import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import enUK from './en-UK';
import ukrUKR from './ukr-UKR';

// @ts-ignore
if (!['en', 'ukr'].includes(localStorage.getItem('i18nextLng'))) {
  localStorage.setItem('i18nextLng', 'en');
}

export default i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: enUK
      },
      ukr: {
        translation: ukrUKR
      }
    }
  });
