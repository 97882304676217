export enum AppActionTypes {

  CREATE_SOCIAL_LINKS_REQUEST = "@@APP/CREATE_SOCIAL_LINKS_REQUEST",
  CREATE_SOCIAL_LINKS_SUCCESS = "@@APP/CREATE_SOCIAL_LINKS_SUCCESS",
  CREATE_SOCIAL_LINKS_FAILURE = "@@APP/CREATE_SOCIAL_LINKS_FAILURE",

  GET_SOCIAL_LINKS_REQUEST = "@@APP/GET_SOCIAL_LINKS_REQUEST",
  GET_SOCIAL_LINKS_SUCCESS = "@@APP/GET_SOCIAL_LINKS_SUCCESS",
  GET_SOCIAL_LINKS_FAILURE = "@@APP/GET_SOCIAL_LINKS_FAILURE",

  UPDATE_SOCIAL_LINKS_REQUEST = "@@APP/UPDATE_SOCIAL_LINKS_REQUEST",
  UPDATE_SOCIAL_LINKS_SUCCESS = "@@APP/UPDATE_SOCIAL_LINKS_SUCCESS",
  UPDATE_SOCIAL_LINKS_FAILURE = "@@APP/UPDATE_SOCIAL_LINKS_FAILURE",

  DELETE_SOCIAL_LINKS_REQUEST = "@@APP/DELETE_SOCIAL_LINKS_REQUEST",
  DELETE_SOCIAL_LINKS_SUCCESS = "@@APP/DELETE_SOCIAL_LINKS_SUCCESS",
  DELETE_SOCIAL_LINKS_FAILURE = "@@APP/DELETE_SOCIAL_LINKS_FAILURE",

  SHOW_NOTIFICATION = "@@APP/SHOW_NOTIFICATION",
  HIDE_NOTIFICATION = "@@APP/HIDE_NOTIFICATION",

  LANGUAGE_PROCESS = "@@APP/LANGUAGE_PROCESS",

}
