import React from 'react';
import { Arrow } from '../components/Arrow';

const smallScreenArrows = {
  prevArrow: <Arrow direction='left' styles={{ left: '-10px' }} />,
  nextArrow: <Arrow direction='right' styles={{ right: '-10px' }} />
};

export const baseSlickSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: false,
  focusOnSelect: false,
  prevArrow: <Arrow direction='left' styles={{ left: '-20px' }} />,
  nextArrow: <Arrow direction='right' styles={{ right: '-20px' }} />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        ...smallScreenArrows
      }
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 2,
        ...smallScreenArrows
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        ...smallScreenArrows
      }
    }
  ]
};
