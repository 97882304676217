import classnames from 'classnames';
import { Location } from 'history';
import { pathToRegexp } from 'path-to-regexp';
import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { ScrollLink, ScrollLinkProps } from 'react-scroll';
import { useWindowDimensions } from '../../../../../shared/hooks';
import { landingAnchors, privateRoutesNames, routesNames } from '../../../constants';
import { INavItem } from '../../../interfaces';
import './navItem.scss';
import { SelectLang } from '../../../../../shared/components/SelectLang';
import { useTranslation } from 'react-i18next';

const checkActive = (
  { pathname, hash: currentHash }: Location<any>,
  path: string,
  additionalActivePaths?: string[]
) => {
  const currentLocation = `${pathname}${currentHash ?? ''}`;

  return (
    pathToRegexp(path).test(currentLocation) ||
    !!additionalActivePaths?.some(path => pathToRegexp(path).test(currentLocation))
  );
};

interface INavItemProps {
  item: INavItem;
  className?: string;
}

const CustomNavLink: React.FC<INavItemProps> = props => {
  const {
    item: { path, hash, style, text, additionalActivePaths },
    className
  } = props;
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <NavLink
      className={classnames('navItem', className)}
      to={{ pathname: path, hash, state: { from: location.pathname } }}
      style={style}
      isActive={(match, location) => checkActive(location, path, additionalActivePaths)}
    >
      {t(`nav_items.${text}`)}
    </NavLink>
  );
};
const CustomHashLink: React.FC<ScrollLinkProps<any> & INavItemProps> = props => {
  const { navItem, className, ...scrollLinkProps } = props;
  const { t } = useTranslation();
  return navItem.hash !== 'lang' ? (
    <div className={classnames('hashItemWrapper', className)}>
      <button {...scrollLinkProps} onClick={() => {}}>
        <CustomNavLink item={navItem} />
      </button>
    </div>
  ) : (
    <SelectLang path={t(navItem.path)} />
  );
};
const ScrollHashLink = ScrollLink(CustomHashLink);

const NavItem: React.FC<INavItemProps> = props => {
  const { item } = props;
  const { path, hash } = item;
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowDimensions();

  const smallScreenDisabled = () => {
    if (width <= 850) {
      if (
        (path === routesNames.HOME && hash === landingAnchors.CONTACT) ||
        path === privateRoutesNames.DASHBOARD_CAREERS ||
        path === privateRoutesNames.DASHBOARD_SUCCESS ||
        path === privateRoutesNames.DASHBOARD_MESSAGES ||
        path === privateRoutesNames.DASHBOARD ||
        path === routesNames.CAREERS ||
        path === routesNames.SUCCESS
      )
        return undefined;
      if (checkActive(location, routesNames.CAREERS)) {
        if (path !== routesNames.HOME || (path === routesNames.HOME && hash !== landingAnchors.COMPANY)) {
          return 'smallScreenDisabled';
        }
      } else {
        if (path !== routesNames.CAREERS) return 'smallScreenDisabled';
      }
    }
  };

  return hash ? (
    <ScrollHashLink
      onSetActive={(to: string) => history.replace({ pathname: path, hash: to })}
      duration={800}
      spy
      smooth
      to={hash}
      navItem={item}
      className={smallScreenDisabled()}
    />
  ) : (
    <CustomNavLink item={item} className={smallScreenDisabled()} />
  );
};

export default NavItem;
