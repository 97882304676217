import { call, fork, put, takeLatest } from "redux-saga/effects";
import api from "../api/api";
import { actions } from "./";
import { LandingActionTypes } from "./constants";
import { AnyAction } from "redux";
import { Type } from "../../App/constants/notificationType";
import notification from '../../../shared/utils/notification'
import i18n from "i18next";

function* createContact ({payload}: AnyAction){
  try {
    const res = yield call(api.createContact, payload)
    if (res) {
      const contacts = yield call(api.getContacts);
      yield put(actions.getContacts.success(contacts));
    }
  } catch (e) {
    yield fork(notification, i18n.t('ERROR'), Type.ERROR)
  }
}

function* getContacts() {
  try {
    const contacts = yield call(api.getContacts);
    yield put(actions.getContacts.success(contacts));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
    yield put(actions.getContacts.failure());
  }
}

function* updateContacts({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateContacts, payload)
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS)
      const contacts = yield call(api.getContacts);
      yield put(actions.getContacts.success(contacts));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
  }
}

function* deleteContact ({payload}: AnyAction) {
  try {
    const res = yield call(api.deleteContact, payload);
    if (res) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS)
      const contacts = yield call(api.getContacts);
      yield put(actions.getContacts.success(contacts))
    }
  } catch (e) {
    yield fork(notification, i18n.t('ERROR'), Type.ERROR)
  }
}

function* createAddress ({payload}: AnyAction) {
  try {
    const res = yield call(api.createAddress, payload)
     if (res) {
       const address = yield call(api.getAddresses);
       yield put(actions.getAddresses.success(address))
     }
  } catch (e) {
    yield fork(notification, i18n.t('ERROR'), Type.ERROR)
  }
}

function* getAddresses() {
  try {
    const addresses = yield call(api.getAddresses);
    yield put(actions.getAddresses.success(addresses));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
    yield put(actions.getAddresses.failure());
  }
}

function* updateAddresses({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateAddresses, payload)
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS)
      const addresses = yield call(api.getAddresses);
      yield put(actions.getAddresses.success(addresses));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
  }
}

function* deleteAddress ({payload}: AnyAction) {
  try {
    const res = yield call(api.deleteAddress, payload);
    if (res) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS)
      const addresses = yield call(api.getAddresses);
      yield put(actions.getAddresses.success(addresses));
    }
  } catch (e) {
    yield fork(notification, i18n.t('ERROR'), Type.ERROR)
  }
}

function * createTechnology ({payload}: AnyAction) {
  try {
    const res = yield call(api.createTechnology, payload)
    if(res) {
      const technologies = yield call(api.getTechnologies);
    yield put(actions.getTechnologies.success(technologies));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
  }
}

function* getTechnologies() {
  try {
    const technologies = yield call(api.getTechnologies);
    yield put(actions.getTechnologies.success(technologies));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
    yield put(actions.getTechnologies.failure());
  }
}

function * deleteTechnology ({payload}: AnyAction) {
  try {
    const res = yield call(api.deleteTechnology, payload);
    if (res) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS)
      const technologies = yield call(api.getTechnologies);
      yield put(actions.getTechnologies.success(technologies));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
  }
}

function* updateTechnologies({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateTechnologies, payload)
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS)
      const technologies = yield call(api.getTechnologies);
      yield put(actions.getTechnologies.success(technologies));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
  }
}

function* createService({ payload }: AnyAction) {
  try {
    const res = yield call(api.createService, payload)
    if (res) {
      const services = yield call(api.getServices);
      yield put(actions.getServices.success(services));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
    yield put(actions.getServices.failure());
  }
}

function* getServices() {
  try {
    const services = yield call(api.getServices);
    yield put(actions.getServices.success(services));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
    yield put(actions.getServices.failure());
  }
}

function* updateService({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateService, payload)
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS)
      const services = yield call(api.getServices);
      yield put(actions.getServices.success(services));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
  }
}

function * deleteService ({payload}: AnyAction){
  try {
    const res = yield call(api.deleteService, payload)
    if(res) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS)
      const services = yield call(api.getServices);
      yield put(actions.getServices.success(services));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
  }
}

function* getCompanyDescription() {
  try {
    const companyDescription = yield call(api.getCompanyDescription);
    yield put(actions.getCompanyDescription.success(companyDescription));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
    yield put(actions.getCompanyDescription.failure());
  }
}

function* updateCompanyDescription({ payload }: AnyAction) {
  try {
    const companyDescription = yield call(api.updateCompanyDescription, payload);
    if (companyDescription) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS)
      yield put(actions.getCompanyDescription.success(companyDescription))
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')} : ${e.message}`, Type.ERROR)
  }
}

export default function* landingSaga() {
  yield takeLatest(LandingActionTypes.CREATE_CONTACTS_REQUEST, createContact);
  yield takeLatest(LandingActionTypes.GET_CONTACTS_REQUEST, getContacts);
  yield takeLatest(LandingActionTypes.UPDATE_CONTACTS_REQUEST, updateContacts);
  yield takeLatest(LandingActionTypes.DELETE_CONTACTS_REQUEST, deleteContact);
  
  yield takeLatest(LandingActionTypes.CREATE_ADDRESSES_REQUEST, createAddress);
  yield takeLatest(LandingActionTypes.GET_ADDRESSES_REQUEST, getAddresses);
  yield takeLatest(LandingActionTypes.UPDATE_ADDRESSES_REQUEST, updateAddresses);
  yield takeLatest(LandingActionTypes.DELETE_ADDRESSES_REQUEST, deleteAddress);

  yield takeLatest(LandingActionTypes.CREATE_SERVICES_REQUEST, createService);
  yield takeLatest(LandingActionTypes.GET_SERVICES_REQUEST, getServices);
  yield takeLatest(LandingActionTypes.UPDATE_SERVICES_REQUEST, updateService);
  yield takeLatest(LandingActionTypes.DELETE_SERVICES_REQUEST, deleteService);

  yield takeLatest(LandingActionTypes.CREATE_TECHNOLOGIES_REQUEST, createTechnology);
  yield takeLatest(LandingActionTypes.GET_TECHNOLOGIES_REQUEST, getTechnologies);
  yield takeLatest(LandingActionTypes.UPDATE_TECHNOLOGIES_REQUEST, updateTechnologies);
  yield takeLatest(LandingActionTypes.DELETE_TECHNOLOGIES_REQUEST, deleteTechnology);

  yield takeLatest(LandingActionTypes.GET_COMPANY_DESCRIPTION_REQUEST, getCompanyDescription);
  yield takeLatest(LandingActionTypes.UPDATE_COMPANY_DESCRIPTION_REQUEST, updateCompanyDescription);
}
