export default {
    success_title: 'Історії Успіху',
    success_title_perline: 'наші',
    project_summary_ukr: 'Узагальнено про проект: ',
    country_ukr: 'Країна: ',
    status_ukr: 'Статус: ',
    team_ukr: 'Команда: ',
    technologies: 'Технології: ',
    text_ukr: 'Опис: ',
    link: 'Посилання: ',
    presentation: 'Презегтація: '
}