import {Quill} from "react-quill";

const fontSizes = [
    "10px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
    "30px",
    "32px",
    "34px",
    "36px",
    "38px",
    "40px",
    "42px",
    "44px",
    "46px",
    "48px",
    "50px",
    "52px",
    "54px",
    "56px",
    "58px",
    "60px",
    "62px",
    "64px",
    "66px",
    "68px",
    "70px",
    "72px"
];
const colorsList = ['#000000', '#f68d2e', '#767676', '#ffffff', '#f5f5f5', '#506a75', '#999999', '#16a085', '#ff5f5f'];

const font = Quill.import('attributors/style/font');
font.whitelist = ['Roboto', 'Agency FB', 'Arial', 'Helvetica'];
Quill.register(font, true);

Quill.register(Quill.import('attributors/style/align'), true);
Quill.register(Quill.import('attributors/style/direction'), true);

const size = Quill.import('attributors/style/size');
size.whitelist = fontSizes;
Quill.register(size, true);

const modules = {
    toolbar: [
        [{font: [false, 'Roboto', 'Agency FB', 'Arial', 'Helvetica']}],
        [{size: fontSizes}],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ "header": 1 }, { "header": 2 }, 'blockquote'],
        [{ 'align': [] }],
        [{ 'color': colorsList }, { 'background': colorsList }],
        ['link', 'image'],
        ['code-block', 'clean']
    ],
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true
    }
};

const formats = [
    "font",
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block"
];

export {modules, formats}
