import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionTitle } from '../../../../shared/components/SectionTitle';
import { actions, selectors } from '../../store';
import './inTouch.scss';
import { useTranslation } from 'react-i18next';
import { getChangeLanguage } from '../../../App/store/selectors';
import { IInTouchDataContacts } from '../../interfaces';
import { POSITIONS } from '../../../../shared/constants/positions';

const positions = Object.values(POSITIONS).filter(pos => pos !== POSITIONS.recruiter);

const renderContacts = (contacts: Array<IInTouchDataContacts>) => {
  return (
    <div className='inTouch-links'>
      {contacts.map(({ link, type, text }: IInTouchDataContacts, index) => (
        <a rel='noopener noreferrer' target='_blank' className={`inTouch-links-item i-${type}`} key={index} href={link}>
          {text}
        </a>
      ))}
    </div>
  );
};
const InTouch: React.FC<{ screen: string }> = props => {
  const { screen } = props;
  const inTouchArr = useSelector(selectors.getInTOuchData());
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const changeLanguage = useSelector(getChangeLanguage());

  useEffect(() => {
    dispatch(actions.getInTouchData.request());
  }, [dispatch]);

  const renderCareersInTouch = () => {
    const recruiter = inTouchArr.find(contact => contact.position === POSITIONS.recruiter);
    if (!recruiter) return null;
    const { position, contacts, image_base64, name } = recruiter;
    return (
      <div
        className={`inTouch-descriptionWrapper ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}
      >
        <div className='inTouch-description'>
          <h2 className='inTouch-description-name'>{name}</h2>
          <h3 className='inTouch-description-position'>{position}</h3>
          {!!contacts && !!contacts.length && renderContacts(contacts)}
        </div>
        <div className='inTouch-imgWrapper'>
          <img className='inTouch-imgWrapper-img' src={image_base64} alt={name} />
        </div>
      </div>
    );
  };

  const renderSuccessInTouch = () => {
    const relevantPositions = inTouchArr.filter(item => positions.includes(item.position));
    return (
      <div className='multipleItems'>
        {relevantPositions.map(person => {
          const { _id, position, name, contacts } = person;
          return (
            <div className='multipleItems-person' key={_id}>
              <h2 className='inTouch-description-name'>{name}</h2>
              <h3 className='inTouch-description-position'>{position}</h3>
              {contacts && contacts.length && renderContacts(contacts)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderRelevantContent = () => {
    switch (screen) {
      case 'careers':
        return renderCareersInTouch();
      case 'success':
        return renderSuccessInTouch();
    }
  };

  return (
    <div className='inTouch section'>
      <SectionTitle
        title={t('containers_careers_components_inTouch.title')}
        preLine={t('containers_careers_components_inTouch.preLine')}
      />
      {renderRelevantContent()}
    </div>
  );
};

export default InTouch;
