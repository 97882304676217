import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AlertComponent } from '../../../shared/components';
import { scrollTop } from '../../../shared/utils';
import { getNotification } from '../../App/store/selectors';
import { InTouch, LookingFor, Offer } from '../components';
import './careersContainer.scss';

const CareersContainer: React.FC = () => {
  const { state, type, message } = useSelector(getNotification());
  useEffect(() => scrollTop(0, 'auto'), []);

  return (
    <div className='careersContainer container section'>
      <Offer />
      <LookingFor />
      <InTouch screen='careers' />
      {state && <AlertComponent message={message} className={type} />}
    </div>
  );
};

export default CareersContainer;
