import React from 'react';
import { Collapse } from 'react-collapse';
import { List } from '../../../../../shared/components/List';
import './vacancyDetails.scss';
import { VacancyForm } from './VacancyForm';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getChangeLanguage } from '../../../../App/store/selectors';

interface IVacancyDetails {
  id: string;
  active: boolean;
  title: string;
  required_skills: string[];
  optional: string[];
  must_have_skills: string[];
}

const VacancyDetails: React.FC<IVacancyDetails> = props => {
  const { id, active, required_skills, optional, must_have_skills, title } = props;
  const { t } = useTranslation();
  const changeLanguage = useSelector(getChangeLanguage());
  return (
    <Collapse
      theme={{
        collapse: 'vacancyDetailsWrapper',
        content: 'vacancyDetails'
      }}
      isOpened={active}
    >
      <div
        className={`vacancyDetails-description ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}
      >
        {!!required_skills.length && (
          <List
            title={t('containers_careers_components_lookingFor_vacancyDetails.req_skills')}
            list={required_skills}
          />
        )}
        {!!optional.length && (
          <List title={t('containers_careers_components_lookingFor_vacancyDetails.optional')} list={optional} />
        )}
        {!!must_have_skills.length && (
          <List
            title={t('containers_careers_components_lookingFor_vacancyDetails.must_have_skills')}
            list={must_have_skills}
          />
        )}
      </div>
      <VacancyForm id={id} title={title} />
    </Collapse>
  );
};

export default VacancyDetails;
