import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SectionTitle} from '../../../../shared/components/SectionTitle';
import {actions, selectors} from '../../store';
import './offer.scss'
import {useTranslation} from "react-i18next";
import {getChangeLanguage} from "../../../App/store/selectors";

const Offer: React.FC = () => {
    const offerList = useSelector(selectors.getOffer())
    const dispatch = useDispatch();
    const { t, i18n: {language } } = useTranslation()
    const changeLanguage = useSelector(getChangeLanguage())

    useEffect(() => {
        dispatch(actions.getOffer.request())
    }, [dispatch])

    return <div className='offer section'>
        <SectionTitle className='offer-title' title={t('containers_careers_components_offer.title')} preLine={t('containers_careers_components_offer.preLine')}/>
        <div className='offer-list'>
            {offerList.map(item =>
                <div key={item._id} className='offer-item'>
                    <img className={`offer-item-image ${changeLanguage ? 'flip-in-hor-top_start' : 'flip-in-hor-top_end'}`} src={item.image_base64} alt={item[`text_${language}`]}/>
                    <h4 className={`offer-item-text ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}>{item[`text_${language}`]}</h4>
                </div>)}
        </div>
    </div>
}

export default Offer
