import classnames from 'classnames';
import React from 'react';
import {ISocialLink} from '../../interfaces';
import './socialLink.scss'

interface IContactProps {
    link: ISocialLink
}

const SocialLink: React.FC<IContactProps> = props => {
    const {link: {_id, link, type}} = props
    return <a
        rel="noopener noreferrer"
        target='_blank'
        key={_id}
        className={classnames('socialLink', `i-${type}`)}
        href={link}
    >
        <i/>
    </a>
}

export default SocialLink
