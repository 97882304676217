import React from "react";
import { useSelector } from "react-redux";
import { AlertComponent } from "../../../../../shared/components";
import { getNotification } from "../../../../App/store/selectors";
import {LookingFor, Offers, InTouch} from '../components'


const CareerContainer: React.FC = () => {
    const {message, type, state} = useSelector(getNotification())
    return <div className="careersContainer container section">
        <Offers />
        <LookingFor />
        <InTouch />
        {state && <AlertComponent message={message} className={type}/>}
        </div>
}

export default CareerContainer;