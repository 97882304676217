export enum CareersActionTypes {
  GET_IN_TOUCH_REQUEST = '@@CAREERS/GET_IN_TOUCH_REQUEST',
  GET_IN_TOUCH_SUCCESS = '@@CAREERS/GET_IN_TOUCH_SUCCESS',
  GET_IN_TOUCH_FAILURE = '@@CAREERS/GET_IN_TOUCH_FAILURE',

  UPDATE_IN_TOUCH_REQUEST = '@@CAREERS/UPDATE_IN_TOUCH_REQUEST',
  UPDATE_IN_TOUCH_SUCCESS = '@@CAREERS/UPDATE_IN_TOUCH_SUCCESS',
  UPDATE_IN_TOUCH_FAILURE = '@@CAREERS/UPDATE_IN_TOUCH_FAILURE',

  CREATE_IN_TOUCH_REQUEST = '@@CAREERS/CREATE_IN_TOUCH_REQUEST',
  CREATE_IN_TOUCH_SUCCESS = '@@CAREERS/CREATE_IN_TOUCH_SUCCESS',
  CREATE_IN_TOUCH_FAILURE = '@@CAREERS/CREATE_IN_TOUCH_FAILURE',

  DELETE_IN_TOUCH_REQUEST = '@@CAREERS/DELETE_IN_TOUCH_REQUEST',
  DELETE_IN_TOUCH_SUCCESS = '@@CAREERS/DELETE_IN_TOUCH_SUCCESS',
  DELETE_IN_TOUCH_FAILURE = '@@CAREERS/DELETE_IN_TOUCH_FAILURE',

  GET_VACANCIES_REQUEST = '@@CAREERS/GET_VACANCIES_REQUEST',
  GET_VACANCIES_SUCCESS = '@@CAREERS/GET_VACANCIES_SUCCESS',
  GET_VACANCIES_FAILURE = '@@CAREERS/GET_VACANCIES_FAILURE',

  CREATE_VACANCIES_REQUEST = '@@CAREERS/CREATE_VACANCIES_REQUEST',
  CREATE_VACANCIES_SUCCESS = '@@CAREERS/CREATE_VACANCIES_SUCCESS',
  CREATE_VACANCIES_FAILURE = '@@CAREERS/CREATE_VACANCIES_FAILURE',

  UPDATE_VACANCIES_REQUEST = '@@CAREERS/UPDATE_VACANCIES_REQUEST',
  UPDATE_VACANCIES_SUCCESS = '@@CAREERS/UPDATE_VACANCIES_SUCCESS',
  UPDATE_VACANCIES_FAILURE = '@@CAREERS/UPDATE_VACANCIES_FAILURE',

  DELETE_VACANCIES_REQUEST = '@@CAREERS/DELETE_VACANCIES_REQUEST',
  DELETE_VACANCIES_SUCCESS = '@@CAREERS/DELETE_VACANCIES_SUCCESS',
  DELETE_VACANCIES_FAILURE = '@@CAREERS/DELETE_VACANCIES_FAILURE',

  ADD_REQUIRED_OPTIONS = '@@CAREERS/ADD_REQUIRED_OPTIONS',
  REMOVE_REQUIRED_OPTIONS = '@@CAREERS/REMOVE_REQUIRED_OPTIONS',

  CREATE_OFFER_REQUEST = '@@CAREERS/CREATE_OFFER_REQUEST',
  CREATE_OFFER_SUCCESS = '@@CAREERS/CREATE_OFFER_SUCCESS',
  CREATE_OFFER_FAILURE = '@@CAREERS/CREATE_OFFER_FAILURE',

  GET_OFFER_REQUEST = '@@CAREERS/GET_OFFER_REQUEST',
  GET_OFFER_SUCCESS = '@@CAREERS/GET_OFFER_SUCCESS',
  GET_OFFER_FAILURE = '@@CAREERS/GET_OFFER_FAILURE',

  UPDATE_OFFER_REQUEST = '@@CAREERS/UPDATE_OFFER_REQUEST',
  UPDATE_OFFER_SUCCESS = '@@CAREERS/UPDATE_OFFER_SUCCESS',
  UPDATE_OFFER_FAILURE = '@@CAREERS/UPDATE_OFFER_FAILURE',

  DELETE_OFFER_REQUEST = '@@CAREERS/DELETE_OFFER_REQUEST',
  DELETE_OFFER_SUCCESS = '@@CAREERS/DELETE_OFFER_SUCCESS',
  DELETE_OFFER_FAILURE = '@@CAREERS/DELETE_OFFER_FAILURE',

  SEND_MESSAGE_REQUEST = '@@CAREERS/SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS = '@@CAREERS/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE = '@@CAREERS/SEND_MESSAGE_FAILURE',

  GET_MESSAGE_REQUEST = '@@CAREERS/GET_MESSAGE_REQUEST',
  GET_MESSAGE_SUCCESS = '@@CAREERS/GET_MESSAGE_SUCCESS',
  GET_MESSAGE_FAILURE = '@@CAREERS/GET_MESSAGE_FAILURE',

  DELETE_MESSAGE_REQUEST = '@@CAREERS/DELETE_MESSAGE_REQUEST',
  DELETE_MESSAGE_SUCCESS = '@@CAREERS/DELETE_MESSAGE_SUCCESS',
  DELETE_MESSAGE_FAILURE = '@@CAREERS/DELETE_MESSAGE_FAILURE'
}
