import { createAsyncAction } from 'typesafe-actions';
import { ISuccessStory } from '../interfaces';
import { SuccessStoriesTypes } from './constants';
import { ISummarySuccess } from '../interfaces/summarySuccess';

export const createSuccessStory = createAsyncAction(
  SuccessStoriesTypes.CREATE_SUCCESS_REQUEST,
  SuccessStoriesTypes.CREATE_SUCCESS_SUCCESS,
  SuccessStoriesTypes.CREATE_SUCCESS_FAILURE
)<object, undefined, undefined>();

export const getSuccessStories = createAsyncAction(
  SuccessStoriesTypes.GET_SUCCESS_REQUEST,
  SuccessStoriesTypes.GET_SUCCESS_SUCCESS,
  SuccessStoriesTypes.GET_SUCCESS_FAILURE
)<undefined, ISuccessStory[], undefined>();

export const updateSuccessStory = createAsyncAction(
  SuccessStoriesTypes.UPDATE_SUCCESS_REQUEST,
  SuccessStoriesTypes.UPDATE_SUCCESS_SUCCESS,
  SuccessStoriesTypes.UPDATE_SUCCESS_FAILURE
)<ISuccessStory, any, undefined>();

export const deleteSuccessStory = createAsyncAction(
  SuccessStoriesTypes.DELETE_SUCCESS_REQUEST,
  SuccessStoriesTypes.DELETE_SUCCESS_SUCCESS,
  SuccessStoriesTypes.DELETE_SUCCESS_FAILURE
)<any, any, undefined>();

export const getSummarySuccess = createAsyncAction(
  SuccessStoriesTypes.GET_SUMMARY_SUCCESS_REQUEST,
  SuccessStoriesTypes.GET_SUMMARY_SUCCESS_SUCCESS,
  SuccessStoriesTypes.GET_SUMMARY_SUCCESS_FAILURE
)<undefined, ISummarySuccess, undefined>();

export const updateSummarySuccess = createAsyncAction(
  SuccessStoriesTypes.UPDATE_SUMMARY_SUCCESS_REQUEST,
  SuccessStoriesTypes.UPDATE_SUMMARY_SUCCESS_SUCCESS,
  SuccessStoriesTypes.UPDATE_SUMMARY_SUCCESS_FAILURE
)<ISummarySuccess, any, undefined>();
