import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import * as Yup from 'yup'
import { StyledEditor } from '../../../../../../shared/components/StyledEditor';
import { CompanyDescription as CompanyDescriptionDetails } from '../../../../../Landing/components/CompanyDescription';
import { ICompanyDescription, ITranslation } from '../../../../../Landing/interfaces';
import { actions, selectors } from '../../../../../Landing/store';
import './_companyDescription.scss';
import { EditToggle } from '../../../../../../shared/components/EditToggle';
import { useTranslation } from 'react-i18next';

interface ICompanyDescriptionProps {}

interface IUpdateObj {
  [key: string]: string;
}

let prepareInitialValues = (values?: ICompanyDescription) => ({
  id: values?._id ?? '',
  title_en: values?.title_en ?? '',
  description_en: values?.description_en ?? '',
  title_ukr: values?.title_ukr ?? '',
  description_ukr: values?.description_ukr ?? ''
});

// const validationSchema = Yup.object({
//     title: Yup.string().required(),
//     description: Yup.string().required()
// })

const CompanyDescription: React.FC<ICompanyDescriptionProps> = props => {
  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState(prepareInitialValues());
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language }
  } = useTranslation() as ITranslation;
  const description = useSelector(selectors.getCompanyDescription());

  let updatedObject: IUpdateObj = { ...description };

  useEffect(() => {
    editMode && description && setInitialValues(prepareInitialValues(description));
  }, [description, editMode]);

  const changeTitle = (data: any) => {
    updatedObject[`title_${language}`] = data;
  };

  const changeDescription = (data: any) => {
    updatedObject[`description_${language}`] = data;
  };

  const saveData = (ev: any) => {
    ev.preventDefault();
    dispatch(actions.updateCompanyDescription.request(updatedObject));
    setEditMode(false);
  };

  return (
    <div className='dashboardCompanyDescription'>
      <EditToggle
        editModeOpened={editMode}
        onClick={() => setEditMode(true)}
        onCancel={() => setEditMode(false)}
        onSave={() => {}}
      />
      {editMode ? (
        <form onSubmit={saveData}>
          <StyledEditor
            placeholder={t(
              `containers_dashboard_containers_landing_components_companyDescription.title_placeholder_${language}`
            )}
            label={t(`containers_dashboard_containers_landing_components_companyDescription.title_label_${language}`)}
            required
            value={updatedObject[`title_${language}`]}
            onChange={changeTitle}
            className='dashboardCompanyDescription-title'
          />
          <StyledEditor
            label={t(
              `containers_dashboard_containers_landing_components_companyDescription.description_label_${language}`
            )}
            placeholder={t(
              `containers_dashboard_containers_landing_components_companyDescription.description_placeholder_${language}`
            )}
            required
            value={updatedObject[`description_${language}`]}
            onChange={changeDescription}
            className='dashboardCompanyDescription-title'
          />
          <div className='editToggle'>
            <button
              // disabled={!validationSchema.isValidSync(values)}
              type='submit'
              className='editToggle-editBtn'
            >
              {t('containers_dashboard_containers_landing_components_companyDescription.submit')}
            </button>
          </div>
        </form>
      ) : (
        <CompanyDescriptionDetails />
      )}
    </div>
  );
};

export default CompanyDescription;
