export const vacancyTemplate = {
  image_base64: '',
  title_en: 'Vacancy',
  title_ukr: 'Вакансія',
  required_skills_en: ['Some skills'],
  required_skills_ukr: ['Досвід роботи в програмувані'],
  optional_en: ['To know another programming language'],
  optional_ukr: ['Володіння іншою мовою програмування'],
  must_have_skills_en: ['Communication'],
  must_have_skills_ukr: ['Комунікабельність']
};
