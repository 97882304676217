import { LoginContainer } from '../../Auth/containers/Login';
import { CareersContainer } from '../../Careers/containers';
import { LandingContainer } from '../../Landing/containers';
import SuccessStories from '../../SuccessStories/SuccessStories';
import { landingAnchors, routesNames } from '../constants';
import { IHashRoute, IRoute } from '../interfaces';

const routesMap: IRoute[] = [
  {
    path: routesNames.CAREERS,
    exact: true,
    component: CareersContainer
  },
  {
    path: routesNames.DASHBOARD_LOGIN,
    exact: true,
    component: LoginContainer
  },
  {
    path: routesNames.SUCCESS,
    exact: true,
    component: SuccessStories
  }
];

const landingAnchorsMap: IHashRoute[] = [
  {
    hash: Object.values(landingAnchors),
    path: routesNames.HOME,
    component: LandingContainer,
    exact: true
  }
];

export { routesMap, landingAnchorsMap };
