export default {
	nav_items: {
		company: 'Компанія',
		services: 'Сервіси',
		technology: 'Технології',
		careers: 'Кар\'єрa',
		contact: 'Контакти',
		landing: 'Лендінг',
		messages: 'Повідомлення',
		logout: 'Вихід',
		'success stories': 'Історії Успіху',
	},
	containers_app_components_footer_follow: 'Підписуйся на нас:',
}
