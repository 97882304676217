import produce from "immer";
import { Reducer } from "redux";
import { ILandingState } from "../interfaces";
import { LandingActionTypes } from "./constants";

const initialState: ILandingState = {
    technologies: [],
    services: [],
    contacts: [],
    addresses: []
};

export const LandingReducer: Reducer<ILandingState> = (state: ILandingState = initialState, action) => {
    switch (action.type) {
        case LandingActionTypes.GET_TECHNOLOGIES_SUCCESS:
            return produce(state, nextState => {
                nextState.technologies = action.payload;
            });
        case LandingActionTypes.GET_SERVICES_SUCCESS:
            return {...state, services: action.payload};
        case LandingActionTypes.GET_CONTACTS_SUCCESS:
            return produce(state, nextState => {
                nextState.contacts = action.payload;
            });
        case LandingActionTypes.GET_ADDRESSES_SUCCESS:
            return produce(state, nextState => {
                nextState.addresses = action.payload;
            });
        case LandingActionTypes.GET_COMPANY_DESCRIPTION_SUCCESS:
            return produce(state, nextState => {
                nextState.companyDescription = action.payload;
            });

        // case LandingActionTypes.UPDATE_COMPANY_DESCRIPTION_SUCCESS:
        //     return produce(state, nextState => {
        //         nextState.companyDescription = action.payload;
        //     })
        default:
            return state;
    }
};
