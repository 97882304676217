import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Address,
    Contact,
    EditableList,
    EditToggle,
    SectionTitle,
    SocialLink
} from '../../../../../../shared/components';
import { TYPES } from '../../../../../../shared/constants';
import { actions as footerActions, selectors as footerSelectors } from '../../../../../App/store';
import { actions, selectors } from '../../../../../Landing/store';
import './contacts.scss'
import { ContactsEditComponent } from './ContactsEditComponent';
import {useTranslation} from "react-i18next";

interface IContactsProps {

}

const Contacts: React.FC<IContactsProps> = props => {
    const [editMode, setEditMode] = useState(false);
    const contacts = useSelector(selectors.getContacts())
    const addresses = useSelector(selectors.getAddresses())
    const footerLinks = useSelector(footerSelectors.getSocialLinks())
    const { t } = useTranslation()

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getContacts.request())
        dispatch(actions.getAddresses.request())
        dispatch(footerActions.getSocialLinks.request())
    }, [dispatch]);

    return <div className='contacts'>
        <EditToggle
            editModeOpened={editMode}
            onClick={() => setEditMode(true)}
            onCancel={() => setEditMode(false)}
            onSave={() => {
            }}
        />
        {!editMode ?
            <>
                <SectionTitle title={t('containers_dashboard_containers_landing_components_contacts.title')} />
                <div className='contacts-addresses'>
                    {addresses.map(item => <Address key={item._id} address={item} />)}
                </div>
                <div className='contacts-contacts'>
                    {contacts.map(item => <Contact key={item._id} contact={item} />)}
                </div>
                <div className='contacts-footerLinks'>
                    <p className='contacts-footerLinks-title'>{t('containers_dashboard_containers_landing_components_contacts.links')}</p>
                    <div className='contacts-footerLinks-list'>
                        {footerLinks.map(item => <SocialLink key={item._id} link={item} />)}
                    </div>
                </div>
            </> :
            <div className='contacts-edit'>
                <div className='contacts-edit-addresses'>
                    <p className='contacts-edit-title'>{t('containers_dashboard_containers_landing_components_contacts.address')}</p>
                    <EditableList
                        type={TYPES.ADDRESSES}
                        list={addresses}
                        onDelete={() => { }}
                        onSave={() => { }}
                        editComponent={ContactsEditComponent}
                    />
                </div>
                <div className='contacts-edit-contacts'>
                    <p className='contacts-edit-title'>{t('containers_dashboard_containers_landing_components_contacts.contacts')}</p>
                    <EditableList
                        type={TYPES.CONTACTS}
                        list={contacts.map(i => ({ ...i, title: i.text }))}
                        onDelete={() => { }}
                        onSave={() => { }}
                        editComponent={ContactsEditComponent}
                    />
                </div>
                <div className='contacts-edit-footerLinks'>
                    <p className='contacts-edit-title'>{t('containers_dashboard_containers_landing_components_contacts.links')}</p>
                    <EditableList
                        type={TYPES.SOCIAL_LINKS}
                        list={footerLinks.map(i => ({ ...i, title: i.link }))}
                        onDelete={() => { }}
                        onSave={() => { }}
                        editComponent={ContactsEditComponent}
                    />
                </div>
            </div>
        }
    </div>
}

export default Contacts
