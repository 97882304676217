export const addressTemplate = {
    title_en: 'Address title',
    address_en: 'Some address',
    city_en: 'Lviv',
    country_en: 'Ukraine',
    title_ukr: 'Назва адреси',
    address_ukr: 'Деяка адреса',
    city_ukr: 'Львів',
    country_ukr: 'Україна'
}
