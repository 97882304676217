export default {
  SERVICES: 'services',
  TECHNOLOGIES: 'technologies',
  CONTACTS: 'contacts',
  ADDRESSES: 'addresses',
  SOCIAL_LINKS: 'social_links',
  OFFERS: 'offers',
  VACANCIES: 'vacancies',
  IN_TOUCH: 'in_touch',
  SUCCESS: 'success stories'
};
