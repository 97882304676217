import React, { useState } from 'react';
import { EditToggle, EditableList, ListEditComponent } from '../../../../../shared/components';
import { useSelector } from 'react-redux';
import { getInTOuchData } from '../../../../Careers/store/selectors';
import { InTouch } from '../../../../Careers';
import { TYPES } from '../../../../../shared/constants';
import { POSITIONS } from '../../../../../shared/constants/positions';

const positions = Object.values(POSITIONS).filter(pos => pos !== POSITIONS.recruiter);

const DashboardInTouchSuccess = () => {
  const [editMode, setEditMode] = useState(false);
  const in_touch = useSelector(getInTOuchData());
  const relevantPeople = in_touch.filter(person => positions.includes(person.position));

  return (
    <div>
      <EditToggle
        editModeOpened={editMode}
        onClick={() => setEditMode(true)}
        onCancel={() => setEditMode(false)}
        onSave={() => {}}
      />
      {editMode ? (
        <EditableList
          type={TYPES.IN_TOUCH}
          list={relevantPeople}
          onDelete={() => {}}
          onSave={() => {}}
          editComponent={ListEditComponent}
        />
      ) : (
        // relevantPeople.map(person => (
        //   <ListEditComponent
        //     type={TYPES.IN_TOUCH}
        //     item={person}
        //     onCancelEdit={() => setEditMode(false)}
        //     key={person._id}
        //   />
        // ))
        <InTouch screen='success' />
      )}
    </div>
  );
};

export default DashboardInTouchSuccess;
