import classnames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createAddress,
  createContact,
  createService,
  createTechnology,
  deleteAddress,
  deleteContact,
  deleteService,
  deleteTechnology
} from '../../../containers/Landing/store/actions';
import {
  createInTouchData,
  createOffer,
  createVacancy,
  deleteInTouchData,
  deleteOffer,
  deleteVacancy
} from '../../../containers/Careers/store/actions';
import { TYPES } from '../../constants';
import { serviceTemplate } from '../../templates/service_template';
import { technologyTemplate } from '../../templates/technology_template';
import './editableList.scss';
import { offerTemplate } from '../../templates/offer_template';
import { vacancyTemplate } from '../../templates/vacancy_template';
import { addressTemplate } from '../../templates/address_template';
import { contactTemplate } from '../../templates/contact_template';
import { createSocialLink, deleteSocialLink } from '../../../containers/App/store/actions';
import { socialLinkTemplate } from '../../templates/socialLink_template';
import { useTranslation } from 'react-i18next';
import { ITranslation } from '../../../containers/Landing/interfaces';
import { createSuccessStory, deleteSuccessStory } from '../../../containers/SuccessStories/store/actions';
import { success_story_template } from '../../templates/success_template';
import { in_touch_template } from '../../templates/in_touch_template';

interface IRequiredItemProps {
  _id: string;
  title?: string;
  title_ukr?: string;
  text_ukr?: string;
  title_en?: string;
  text_en?: string;
  text?: string;
  image_base64?: string;
  project_summary_en?: string;
  project_summary_ukr?: string;
  country_en?: string;
  country_ukr?: string;
  status_en?: string;
  status_ukr?: string;
  team_en?: string;
  team_ukr?: string;
  technologies?: string;
  description_en?: string;
  description_ukr?: string;
  link?: string;
  presentation?: string;
}

interface IEditableListProps {
  list: IRequiredItemProps[];
  type: string;

  onDelete(): void;
  onSave(_id: string): void;

  editComponent: React.FC<any>;
}

const getTitle = (item: any, language: string) => {
  if (item[`title_${language}`]) return item[`title_${language}`];
  if (item.title) return item.title;
  if (item[`text_${language}`]) return item[`text_${language}`];
  if (item.name) return item.name;
  return item.text || '';
};

const EditableList: React.FC<any> = props => {
  const { list, type, editComponent: EditComponent } = props;
  const [editableItems, setEditableItems] = useState<string[]>([]);
  const dispatch = useDispatch();
  const {
    i18n: { language }
  } = useTranslation() as ITranslation;

  const onEditItem = (_id: string) => {
    setEditableItems(prevState => [_id, ...prevState]);
  };

  const onCancelEdit = (_id: string) => {
    setEditableItems(prevState => prevState.filter(i => i !== _id));
  };

  const isItemEdit = (_id: string) => editableItems.includes(_id);

  const addNewItem = () => {
    switch (type) {
      case TYPES.SERVICES:
        dispatch(createService.request(serviceTemplate));
        break;
      case TYPES.TECHNOLOGIES:
        dispatch(createTechnology.request(technologyTemplate));
        break;
      case TYPES.OFFERS:
        dispatch(createOffer.request(offerTemplate));
        break;
      case TYPES.VACANCIES:
        dispatch(createVacancy.request(vacancyTemplate));
        break;
      case TYPES.SUCCESS:
        dispatch(createSuccessStory.request(success_story_template));
        break;
      case TYPES.ADDRESSES:
        dispatch(createAddress.request(addressTemplate));
        break;
      case TYPES.CONTACTS:
        dispatch(createContact.request(contactTemplate));
        break;
      case TYPES.SOCIAL_LINKS:
        dispatch(createSocialLink.request(socialLinkTemplate));
        break;
      case TYPES.IN_TOUCH:
        dispatch(createInTouchData.request(in_touch_template));
        break;
    }
  };

  const onDeleteItem = (_id: string) => {
    const yes = window.confirm(`Do you really want to delete the ${type}?`);
    if (yes) {
      switch (type) {
        case TYPES.SERVICES:
          dispatch(deleteService.request({ _id }));
          break;
        case TYPES.TECHNOLOGIES:
          dispatch(deleteTechnology.request({ _id }));
          break;
        case TYPES.OFFERS:
          dispatch(deleteOffer.request({ _id }));
          break;
        case TYPES.VACANCIES:
          dispatch(deleteVacancy.request({ _id }));
          break;
        case TYPES.ADDRESSES:
          dispatch(deleteAddress.request({ _id }));
          break;
        case TYPES.CONTACTS:
          dispatch(deleteContact.request({ _id }));
          break;
        case TYPES.SOCIAL_LINKS:
          dispatch(deleteSocialLink.request({ _id }));
          break;
        case TYPES.SUCCESS:
          dispatch(deleteSuccessStory.request({ _id }));
          break;
        case TYPES.IN_TOUCH:
          dispatch(deleteInTouchData.request({ _id }));
          break;
      }
    }
  };

  return (
    <div className='editableList'>
      {list.map(
        (item: { [x: string]: any; title?: any; text?: any; _id?: any }, index: React.Key | null | undefined) => {
          const { _id } = item;

          return (
            <div key={index} className={classnames('editableList-item', { active: isItemEdit(_id) })}>
              <div className='editableList-preview'>
                <p className='editableList-preview-title'>{getTitle(item, language)}</p>
                {!isItemEdit(_id) ? (
                  <div className='editableList-preview-actions'>
                    <button>
                      <img onClick={() => onEditItem(_id)} src='/icons/edit.png' alt='edit' />
                    </button>
                    <button>
                      <img onClick={() => onDeleteItem(_id)} src='/icons/delete.png' alt='delete' />
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {isItemEdit(_id) && <EditComponent item={item} type={type} onCancelEdit={onCancelEdit} />}
            </div>
          );
        }
      )}
      <div className='editableList-item'>
        <div className='editableList-preview'>
          <p className='editableList-preview-title'>ADD NEW ITEM</p>
          <img src='/icons/plus.svg' alt='plus' onClick={() => addNewItem()} className='pointer' />
        </div>
      </div>
    </div>
  );
};

export default EditableList;
