import { AnyAction } from "redux";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import api from "../api/api";
import { Type } from "../constants/notificationType";
import { actions } from "./";
import { AppActionTypes } from "./constants";
import notification from '../../../shared/utils/notification';
import i18n from "i18next";

function* createSocialLink({ payload }: AnyAction) {
    try {
        const res = yield call(api.createSocialLink, payload)
        if (res) {
            const socialLink = yield call(api.getSocialLinks)
            yield put(actions.getSocialLinks.success(socialLink))
        }
    } catch (e) {
        yield fork(notification, i18n.t('ERROR'), Type.ERROR)
    }
}

function* getSocialLinks() {
    try {
        const socialLinks = yield call(api.getSocialLinks);
        yield put(actions.getSocialLinks.success(socialLinks));
    } catch (e) {
        yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR)
        yield put(actions.getSocialLinks.failure());
    }
}

function* updateSocialLinks({ payload }: AnyAction) {
    try {
        const res = yield call(api.updateSocialLinks, payload)
        if (res) {
            yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS)
            const socialLinks = yield call(api.getSocialLinks);
            yield put(actions.getSocialLinks.success(socialLinks));
        }
    } catch (e) {
        yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR)
    }
}

function* deleteSocialLink({ payload }: AnyAction) {
    try {
        const res = yield call(api.deleteSocialLink, payload)
        if (res) {
            yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS)
            const socialLinks = yield call(api.getSocialLinks);
            yield put(actions.getSocialLinks.success(socialLinks));
        }
    } catch (e) {
        yield fork(notification, i18n.t('ERROR'), Type.ERROR)
    }
}

export default function* appSaga() {
    yield takeLatest(AppActionTypes.CREATE_SOCIAL_LINKS_REQUEST, createSocialLink);
    yield takeLatest(AppActionTypes.GET_SOCIAL_LINKS_REQUEST, getSocialLinks);
    yield takeLatest(AppActionTypes.UPDATE_SOCIAL_LINKS_REQUEST, updateSocialLinks);
    yield takeLatest(AppActionTypes.DELETE_SOCIAL_LINKS_REQUEST, deleteSocialLink);
}
