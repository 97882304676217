import { createAsyncAction } from 'typesafe-actions';
import { IOffer } from '../../App/interfaces';
import { IMessage } from '../../Dashboard/containers/Messages/interfaces/IMessage';
import { IInTouchData, IVacancy } from '../interfaces';
import { CareersActionTypes } from './constants';

export const getInTouchData = createAsyncAction(
  CareersActionTypes.GET_IN_TOUCH_REQUEST,
  CareersActionTypes.GET_IN_TOUCH_SUCCESS,
  CareersActionTypes.GET_IN_TOUCH_FAILURE
)<undefined, IInTouchData[], undefined>();

export const updateInTouchData = createAsyncAction(
  CareersActionTypes.UPDATE_IN_TOUCH_REQUEST,
  CareersActionTypes.UPDATE_IN_TOUCH_SUCCESS,
  CareersActionTypes.UPDATE_IN_TOUCH_FAILURE
)<IInTouchData, any, undefined>();

export const createInTouchData = createAsyncAction(
  CareersActionTypes.CREATE_IN_TOUCH_REQUEST,
  CareersActionTypes.CREATE_IN_TOUCH_SUCCESS,
  CareersActionTypes.CREATE_IN_TOUCH_FAILURE
)<object, undefined, undefined>();

export const deleteInTouchData = createAsyncAction(
  CareersActionTypes.DELETE_IN_TOUCH_REQUEST,
  CareersActionTypes.DELETE_IN_TOUCH_SUCCESS,
  CareersActionTypes.DELETE_IN_TOUCH_FAILURE
)<object, any, undefined>();

export const createVacancy = createAsyncAction(
  CareersActionTypes.CREATE_VACANCIES_REQUEST,
  CareersActionTypes.CREATE_VACANCIES_SUCCESS,
  CareersActionTypes.CREATE_VACANCIES_FAILURE
)<object, undefined, undefined>();

export const getVacancies = createAsyncAction(
  CareersActionTypes.GET_VACANCIES_REQUEST,
  CareersActionTypes.GET_VACANCIES_SUCCESS,
  CareersActionTypes.GET_VACANCIES_FAILURE
)<undefined, IVacancy[], undefined>();

export const updateVacancies = createAsyncAction(
  CareersActionTypes.UPDATE_VACANCIES_REQUEST,
  CareersActionTypes.UPDATE_VACANCIES_SUCCESS,
  CareersActionTypes.UPDATE_VACANCIES_FAILURE
)<IVacancy, any, undefined>();

export const deleteVacancy = createAsyncAction(
  CareersActionTypes.DELETE_VACANCIES_REQUEST,
  CareersActionTypes.DELETE_VACANCIES_SUCCESS,
  CareersActionTypes.DELETE_VACANCIES_FAILURE
)<object, any, undefined>();

export const addRequiredOption = (key = 'must_have_skills', _id: string) => ({
  type: CareersActionTypes.ADD_REQUIRED_OPTIONS,
  payload: { key, _id }
});

export const createOffer = createAsyncAction(
  CareersActionTypes.CREATE_OFFER_REQUEST,
  CareersActionTypes.CREATE_OFFER_SUCCESS,
  CareersActionTypes.CREATE_OFFER_FAILURE
)<object, undefined, undefined>();

export const getOffer = createAsyncAction(
  CareersActionTypes.GET_OFFER_REQUEST,
  CareersActionTypes.GET_OFFER_SUCCESS,
  CareersActionTypes.GET_OFFER_FAILURE
)<undefined, IOffer[], undefined>();

export const updateOffer = createAsyncAction(
  CareersActionTypes.UPDATE_OFFER_REQUEST,
  CareersActionTypes.UPDATE_OFFER_SUCCESS,
  CareersActionTypes.UPDATE_OFFER_FAILURE
)<IOffer, any, undefined>();

export const deleteOffer = createAsyncAction(
  CareersActionTypes.DELETE_OFFER_REQUEST,
  CareersActionTypes.DELETE_OFFER_SUCCESS,
  CareersActionTypes.DELETE_OFFER_FAILURE
)<object, any, undefined>();

export const sendMessage = createAsyncAction(
  CareersActionTypes.SEND_MESSAGE_REQUEST,
  CareersActionTypes.SEND_MESSAGE_SUCCESS,
  CareersActionTypes.SEND_MESSAGE_FAILURE
)<boolean, undefined, undefined>();

export const getMessages = createAsyncAction(
  CareersActionTypes.GET_MESSAGE_REQUEST,
  CareersActionTypes.GET_MESSAGE_SUCCESS,
  CareersActionTypes.GET_MESSAGE_FAILURE
)<undefined, IMessage, any>();

export const deleteMessage = createAsyncAction(
  CareersActionTypes.DELETE_MESSAGE_REQUEST,
  CareersActionTypes.DELETE_MESSAGE_SUCCESS,
  CareersActionTypes.DELETE_MESSAGE_FAILURE
)<any, undefined, undefined>();
