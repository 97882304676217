import classnames from 'classnames';
import { FormikErrors } from 'formik';
import React, { ChangeEvent, FocusEvent, useRef } from 'react';
import { InputType, InputTypes } from '../../constants';
import './styledInput.scss';

interface IStyledInput {
  name: string;
  ref?: any;
  value?: string | number;
  defaultValue?: string | number;
  placeholder?: string;
  error?: boolean;
  helperText?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[];
  label?: any;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  onChange: any;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  className?: string;
  onClick?: () => void;
  fullWidth?: boolean;
  icon?: string;
  thousandsSeparators?: boolean;
  max?: number;
  onClear?(): void;
  type?: string;
  disabled?: boolean;
  updatedData?: any;
  index?: number;
  contacts?: boolean;
}

const valueToInteger = (value: string) => {
  return Number(value.replace(/,/g, ''));
};

const numberWithThousandsSeparators = (value: string | number) => {
  return value ? valueToInteger(value.toString()).toLocaleString('en') : '';
};

const getValue = (label: string, value: any, type: string, thousandsSeparators: boolean | undefined) => {
  if (type === InputTypes.file) return;
  if (!label && type === InputTypes.password && thousandsSeparators) {
    return numberWithThousandsSeparators(value);
  }
  return value;
};

const StyledInput: React.FC<IStyledInput> = props => {
  let {
    value = '',
    defaultValue,
    placeholder,
    error,
    label,
    required,
    name,
    onChange,
    helperText,
    multiline,
    rows,
    onFocus,
    onBlur,
    className,
    onClick,
    fullWidth,
    icon,
    thousandsSeparators,
    max,
    onClear,
    type = InputTypes.text,
    disabled,
    index,
    contacts
  } = props;

  const inputRef = useRef<any>();

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
    const { value: inputValue, files } = e.target;
    let key = name;

    switch (type) {
      case InputTypes.number:
        const numberRegExp = new RegExp('^[0-9]*$');
        if (!inputValue.match(numberRegExp)) return;
        break;
      case InputTypes.phone:
        const phoneRegExp = new RegExp('^[+()0-9]*$');
        if (!inputValue.match(phoneRegExp)) return;
        break;
      case InputTypes.file: {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function () {
          onChange(key, reader.result);
        };
        return;
      }
    }
    if (max && valueToInteger(inputValue) > max) return;
    if (typeof index === 'number') {
      key = contacts ? `contacts[${index}].${name}` : `${name}[${index}]`;
    }
    onChange(key, inputValue);
  };

  const inputChange = (e: any) => {
    return label ? handleChange(e) : onChange(e);
  };

  const renderTextArea = () => {
    return (
      <textarea
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={e => inputChange(e)}
        ref={inputRef}
        name={name}
        rows={rows}
        className='styledInput-input styledInput-textarea'
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  };

  const renderTextInput = () => {
    return (
      <input
        className={classnames('styledInput-input', {
          'styledInput-input-withIcon': icon,
          'styledInput-input-withClear': onClear
        })}
        ref={inputRef}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={e => inputChange(e)}
        name={name}
        type={type}
        value={getValue(label, value, type, thousandsSeparators)}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  };

  const renderRelevantInput = () => {
    if (multiline) return renderTextArea();
    if (type === InputTypes.file) {
      return (
        <div
          className={classnames('styledInput-input', {
            'styledInput-input-withIcon': icon,
            'styledInput-input-withClear': onClear
          })}
        >
          <span className='file-name'>{value || 'Select presentation file'}</span>
          {renderTextInput()}
          {value && (
            <button className='remove-file' onClick={() => onChange(name, '')}>
              <img src='/icons/cancel.png' alt='remove' />
            </button>
          )}
        </div>
      );
    }
    return renderTextInput();
  };

  return (
    <div
      onClick={onClick}
      className={classnames('styledInput', { styledInputError: error }, className, {
        'styledSelect-fullWidth': fullWidth
      })}
    >
      {label && <p className={classnames('styledInput-label', { 'defaultLabel-required': required })}>{label}</p>}
      <div className='styledInput-inputWrap'>
        {icon && <img className='styledInput-icon' src={icon} alt={icon} />}
        {onClear && value && (
          <img onClick={onClear} className='styledInput-clear' src='/icons/ic_close.svg' alt='ic_close' />
        )}
        {renderRelevantInput()}
      </div>
      {!!helperText && <p className={'styledInput-helperText'}>{helperText}</p>}
    </div>
  );
};

export default StyledInput;
