import classnames from 'classnames';
import React from 'react';
import { IContact } from '../../interfaces';
import './contact.scss';
interface IContactProps {
  contact: IContact;
}

const Contact: React.FC<IContactProps> = props => {
  const { contact } = props;
  return (
    <a
      rel='noopener noreferrer'
      target='_blank'
      key={contact._id}
      href={contact.link}
      className={classnames('contact', `i-${contact.type}`)}
    >
      {contact.text}
    </a>
  );
};

export default Contact;
