import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AlertComponent, EditToggle, EditableList, ListEditComponent } from '../../../../shared/components';
import { getNotification } from '../../../App/store/selectors';
import SuccessStories from '../../../SuccessStories/SuccessStories';
import { TimelycodeStore } from '../../../../shared/types';
import { TYPES } from '../../../../shared/constants';
import { useTranslation } from 'react-i18next';
import DashboardInTouchSuccess from './components/DashboardInTouchSuccess';
import DashboardSummarySuccess from './components/DashboardSummarySucces';

const DashboardSuccess: React.FC = () => {
  const { message, type, state } = useSelector(getNotification());
  const [editMode, setEditMode] = useState(false);
  const { stories } = useSelector((state: TimelycodeStore) => state.success);
  const { t } = useTranslation();

  return (
    <div className='careersContainer container section'>
      <div className='dashboardCompanyDescription'>
        {editMode ? (
          <div className='contacts-edit'>
            <EditToggle
              editModeOpened={editMode}
              onClick={() => setEditMode(true)}
              onCancel={() => setEditMode(false)}
              onSave={() => {}}
            />
            <div className='contacts-edit-addresses'>
              <p className='contacts-edit-title'>{t('success_title')}</p>
              {!!stories.length && (
                <EditableList
                  type={TYPES.SUCCESS}
                  list={stories}
                  onDelete={() => {}}
                  onSave={() => {}}
                  editComponent={ListEditComponent}
                />
              )}
            </div>
          </div>
        ) : (
          <>
            <DashboardSummarySuccess />
            <EditToggle
              editModeOpened={editMode}
              onClick={() => setEditMode(true)}
              onCancel={() => setEditMode(false)}
              onSave={() => {}}
            />
            <SuccessStories isAdmin={true} />
            <DashboardInTouchSuccess />
          </>
        )}
      </div>
      {state && <AlertComponent message={message} className={type} />}
    </div>
  );
};

export default DashboardSuccess;
