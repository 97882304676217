import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { EditableList, EditToggle, ListEditComponent } from '../../../../../../shared/components';
import { TYPES } from '../../../../../../shared/constants';
import { Services as ServicesDetails } from '../../../../../Landing/components/Services'
import { getServices } from '../../../../../Landing/store/selectors';
import './services.scss'

interface IServicesProps {

}

export const Services: React.FC<IServicesProps> = props => {
    const [editMode, setEditMode] = useState(false);
    const services = useSelector(getServices());
    const saveChanges = (id: string) => {
    }


    return <div>
        <EditToggle
            editModeOpened={editMode}
            onClick={() => setEditMode(true)}
            onCancel={() => setEditMode(false)}
            onSave={() => {
            }}
        />
        {editMode ?
            <EditableList
                type={TYPES.SERVICES}
                onDelete={() => { }}
                onSave={saveChanges}
                list={services}
                editComponent={ListEditComponent}
            /> : <ServicesDetails />}
    </div>
}

export default Services
