import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, withRouter } from "react-router-dom";
import { IRouteComponentProps } from "../../../../shared/interfaces";
import { authentificated } from "../../../Auth/store/selectors";
import {routesNames} from '../../constants'


interface PrivateRouteProps extends RouteProps {
  path: string | string[];
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps & IRouteComponentProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = useSelector(authentificated());

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated && Component) {
            return <Component {...props} />;
        }
          return (
          <Redirect
            to={{
              pathname: routesNames.DASHBOARD_LOGIN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
export default withRouter(PrivateRoute);
