export default {
    success_title: 'Success Stories',
    success_title_perline: 'our',
    project_summary_en: 'Project summary: ',
    country_en: 'Country: ',
    status_en: 'Status: ',
    team_en: 'Team: ',
    technologies: 'Technologies: ',
    text_en: 'Description: ',
    link: 'Link: ',
    presentation: 'Presentation'
}