import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SectionTitle} from '../../../../shared/components/SectionTitle';
import {actions, selectors} from '../../store';
import './technologies.scss'
import {useTranslation} from "react-i18next";
import {ITranslation} from "../../interfaces";
import {getChangeLanguage} from "../../../App/store/selectors";

const Technologies: React.FC = () => {
    const technologies = useSelector(selectors.getTechnologies())
    const dispatch = useDispatch();
    const { t, i18n: {language} } = useTranslation() as ITranslation
    const changeLanguage = useSelector(getChangeLanguage())
    useEffect(() => {
        dispatch(actions.getTechnologies.request())
    }, [dispatch])

    return <section className='technologies section container'>
        <SectionTitle title={t('containers_landing_components_technologies')}/>
        <div className='technologies-list'>
            {technologies.map(item =>
                <div key={item._id} className='technologies-list-item technology'>
                    <img className={`technology-image ${changeLanguage ? 'flip-in-hor-top_start' : 'flip-in-hor-top_end'}`} src={item.image_base64} alt={item[`title_${language}`]}/>
                    <h3 className={`technology-title ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}>{item[`title_${language}`]}</h3>
                    <p className={`technology-text ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}>{item[`text_${language}`]}</p>
                </div>)}
        </div>
    </section>
}

export default Technologies
