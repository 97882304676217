import React, { useState } from 'react';
import Summary from '../../../../SuccessStories/components/Summary';
import { EditToggle, StyledEditor } from '../../../../../shared/components';
import { useDispatch, useSelector } from 'react-redux';
import { TimelycodeStore } from '../../../../../shared/types';
import { useTranslation } from 'react-i18next';
import { actions } from '../../../../SuccessStories/store';

const DashboardSummarySuccess = () => {
  const [editMode, setEditMode] = useState(false);
  const { summary } = useSelector((state: TimelycodeStore) => state.success);
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const dispatch = useDispatch();

  const docToUpdate = { ...summary };

  const saveData = (e: any) => {
    e.preventDefault();
    dispatch(actions.updateSummarySuccess.request(docToUpdate));
    setEditMode(false);
  };

  const changeDescription = (data: string) => {
    // @ts-ignore
    docToUpdate[`description_${language}`] = data;
  };

  return (
    <div className='dashboardCompanyDescription'>
      <EditToggle
        editModeOpened={editMode}
        onClick={() => setEditMode(true)}
        onCancel={() => setEditMode(false)}
        onSave={() => {}}
      />
      {editMode ? (
        <form onSubmit={saveData}>
          <StyledEditor
            label={t(
              `containers_dashboard_containers_landing_components_companyDescription.description_label_${language}`
            )}
            placeholder={t(
              `containers_dashboard_containers_landing_components_companyDescription.description_placeholder_${language}`
            )}
            required
            // @ts-ignore
            value={docToUpdate[`description_${language}`]}
            onChange={changeDescription}
            className='dashboardCompanyDescription-title'
          />
          <div className='editToggle'>
            <button type='submit' className='editToggle-editBtn'>
              {t('containers_dashboard_containers_landing_components_companyDescription.submit')}
            </button>
          </div>
        </form>
      ) : (
        <Summary />
      )}
    </div>
  );
};

export default DashboardSummarySuccess;
