import { API, METHODS } from "../../../shared/constants";
import { IAddress, IContact } from "../../../shared/interfaces";
import { request } from "../../../shared/utils";
import { ICompanyDescription, IService, ITechnology } from "../interfaces";

export default {
    getCompanyDescription: () => request(METHODS.GET, API.LANDING.COMPANY_DESCRIPTION)(),
    updateCompanyDescription: (payload: ICompanyDescription) => request(METHODS.POST, API.LANDING.COMPANY_DESCRIPTION)(payload),

    createContact: (payload: IContact) => request(METHODS.POST, API.LANDING.CREATE_CONTACT)(payload),
    getContacts: () => request(METHODS.GET, API.LANDING.CONTACTS)(),
    updateContacts: (payload: IContact) => request(METHODS.POST, API.LANDING.CONTACTS)(payload),
    deleteContact: (payload: object) => request(METHODS.POST, API.LANDING.DELETE_CONTACT)(payload),

    createAddress: (payload: IAddress) => request(METHODS.POST, API.LANDING.CREATE_ADDRESS)(payload),
    getAddresses: () => request(METHODS.GET, API.LANDING.ADDRESSES)(),
    updateAddresses: (payload: IAddress) => request(METHODS.POST, API.LANDING.ADDRESSES)(payload),
    deleteAddress: (payload: object)=> request(METHODS.POST, API.LANDING.DELETE_ADDRESS)(payload),
    
    createService: (payload: IService) => request(METHODS.POST, API.LANDING.CREATE_SERVICE)(payload),
    getServices: () => request(METHODS.GET, API.LANDING.SERVICES)(),
    updateService: (payload: IService) => request(METHODS.POST, API.LANDING.SERVICES)(payload),
    deleteService: (payload: object) => request(METHODS.POST, API.LANDING.DELETE_SERVICE)(payload),

    createTechnology: (payload: ITechnology) => request(METHODS.POST, API.LANDING.CREATE_TECHNOLOGY)(payload),
    getTechnologies: () => request(METHODS.GET, API.LANDING.TECHNOLOGIES)(),
    updateTechnologies: (payload: ITechnology) => request(METHODS.POST, API.LANDING.TECHNOLOGIES)(payload),
    deleteTechnology: (payload: object) => request(METHODS.POST, API.LANDING.DELETE_TECHNOLOGY)(payload)
};
