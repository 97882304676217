import { pathToRegexp } from 'path-to-regexp';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import history from '../../../../shared/history/history';
import {
  DASHBOARD_NAV_ITEMS,
  landingAnchors,
  landingAnchorsMap,
  NAV_ITEMS,
  privateRoutesNames,
  routesMap,
  routesNames
} from '../../constants';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Page } from '../Page';
import { PrivateRoute } from '../PrivateRoute';
import PrivateRoutes from './PrivateRoutes';
import { useTranslation } from 'react-i18next';

const defaultHeaderFixedRoutes = [routesNames.CAREERS, routesNames.SUCCESS];
const headerDisabledRoutes = [routesNames.DASHBOARD_LOGIN];
const footerDisabledRoutes = [
  routesNames.DASHBOARD_LOGIN,
  privateRoutesNames.DASHBOARD,
  privateRoutesNames.DASHBOARD_CAREERS,
  privateRoutesNames.DASHBOARD_SUCCESS
];

const Routes: React.FunctionComponent = () => {
  const navRoutes = [...landingAnchorsMap, ...routesMap].map((route, index) => <Page key={index} {...route} />);
  const {
    i18n: { language }
  } = useTranslation();
  const headerFixed = ({ location: { pathname } }: RouteComponentProps) => {
    return defaultHeaderFixedRoutes.some(path => pathToRegexp(path).test(pathname));
  };

  const filteredNavItems = language === 'ukr' ? NAV_ITEMS.filter(nav => nav.text !== 'success stories') : NAV_ITEMS;

  const HeaderComponent = (props: RouteComponentProps) => {
    const {
      location: { pathname }
    } = props;
    const isNavVisible = !headerDisabledRoutes.some(path => pathToRegexp(path).test(pathname));
    const isDashboardNav =
      pathToRegexp(privateRoutesNames.DASHBOARD_CAREERS).test(pathname) ||
      pathToRegexp(privateRoutesNames.DASHBOARD_SUCCESS).test(pathname) ||
      pathToRegexp(privateRoutesNames.DASHBOARD_MESSAGES).test(pathname) ||
      pathToRegexp(privateRoutesNames.DASHBOARD).test(pathname);

    return isNavVisible ? (
      isDashboardNav ? (
        <Header navItems={DASHBOARD_NAV_ITEMS} defaultFixed />
      ) : (
        <Header navItems={filteredNavItems} defaultFixed={headerFixed(props)} />
      )
    ) : null;
  };

  const FooterComponent = ({ location: { pathname } }: RouteComponentProps) =>
    !footerDisabledRoutes.some(path => pathToRegexp(path).test(pathname)) && <Footer />;

  return (
    <Router history={history}>
      <Route render={HeaderComponent} />
      <section className='content'>
        <Switch>
          {navRoutes}
          <PrivateRoute path='/dashboard' component={PrivateRoutes} />
          <Route render={() => <Redirect to={{ pathname: routesNames.HOME, hash: landingAnchors.COMPANY }} />} />
        </Switch>
      </section>
      <Route render={FooterComponent} />
    </Router>
  );
};

export default Routes;
