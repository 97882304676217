import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import {routesNames} from '../../containers/App/constants';
import history from '../history/history';
import {tokenHandler} from './index';

axios.defaults.headers.post["Content-Type"] = "application/json";

export const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(function(config) {
  const token = tokenHandler.get();
  const {location: {pathname}} = history;
  if (pathname.includes('dashboard')) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

const makeRequest = (instance: AxiosInstance) => (method: string, url: string, params: any) => {
    // @ts-ignore
  return instance[method](url, ...params);
};

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    return res.data || res;
  },
  (error: AxiosError) => {
    const { response } = error || {};
    const { data } = response || {};
    const {status} = {...response};
    if (status === 401) {
      tokenHandler.remove();
      history.push(routesNames.DASHBOARD_LOGIN)
    }
    if (data) {
      return Promise.reject(data);
    }
    return Promise.reject(error);
  },
);
/**
 * Axios wrapper
 *
 * @param  {string} method Method of the request
 * @param  {string} url url of the request
 *
 * @return {object} wrapped axios function that receives params
 */
export default (method: string, url: string) => (...params: any[]) => makeRequest(axiosInstance)(method, url, params);
