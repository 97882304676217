const clickListeners = (
  element: HTMLElement | Document,
  listener: (event: MouseEvent | TouchEvent) => void,
  remove?: boolean,
) => {
  if (!remove) {
    element.addEventListener("mousedown", listener as EventListener);
    element.addEventListener("touchstart", listener as EventListener);
  } else {
    element.removeEventListener("mousedown", listener as EventListener);
    element.removeEventListener("touchstart", listener as EventListener);
  }
};

export default clickListeners;
