import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { EditableList, EditToggle, ListEditComponent } from '../../../../../../shared/components';
import { TYPES } from '../../../../../../shared/constants';
import { selectors } from '../../../../../Careers/store';
import { LookingFor as LookingForDetails } from '../../../../../Careers/components/LookingFor';
import { useTranslation } from 'react-i18next';

const LookingFor: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const vacancies = useSelector(selectors.getVacancies());
  const { t } = useTranslation();

  return (
    <div className='dashboardCompanyDescription'>
      <EditToggle
        editModeOpened={editMode}
        onClick={() => setEditMode(true)}
        onCancel={() => setEditMode(false)}
        onSave={() => {}}
      />
      {editMode ? (
        <div className='contacts-edit'>
          <div className='contacts-edit-addresses'>
            <p className='contacts-edit-title'>{t('containers_careers_components_lookingFor.title')}</p>
            <EditableList
              type={TYPES.VACANCIES}
              list={vacancies}
              onDelete={() => {}}
              onSave={() => {}}
              editComponent={ListEditComponent}
            />
          </div>
        </div>
      ) : (
        <LookingForDetails />
      )}
    </div>
  );
};

export default LookingFor;
