import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface IHashRoute extends RouteProps {
    hash: string | string[],
}

const HashRoute: React.FC<IHashRoute> = ({ hash, ...routeProps }) => (
    <Route
        render={({history: {location}}) =>
            (((typeof hash === 'string' && hash === location.hash) || hash.includes(location.hash)) &&
                <Route {...routeProps} />)}
    />
);

export default HashRoute;
