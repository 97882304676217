import React from 'react';
import './sectionTitle.scss';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getChangeLanguage } from '../../../containers/App/store/selectors';

interface ISectionTitle {
  preLine?: string;
  title: string;
  className?: string;
}

const SectionTitle: React.FC<ISectionTitle> = ({ title, preLine, className }) => {
  const changeLanguage = useSelector(getChangeLanguage());
  return (
    <div
      className={classnames('sectionTitle', className, changeLanguage ? 'rotate-in-hor_start' : 'rotate-in-hor_end')}
    >
      {preLine && <h2 className='sectionTitle-preLine'>{preLine}</h2>}
      <h1 className='sectionTitle-text'>{title}</h1>
    </div>
  );
};

export default SectionTitle;
