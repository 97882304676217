import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import {SocialLink} from '../../../../shared/components';
import {actions, selectors} from '../../store';
import './footer.scss'
import {getChangeLanguage} from "../../store/selectors";

const Footer: React.FC = () => {
    const dispatch = useDispatch();
    const socialLinks = useSelector(selectors.getSocialLinks())
    const { t } = useTranslation()
    const changeLanguage = useSelector(getChangeLanguage())

    useEffect(() => {
        dispatch(actions.getSocialLinks.request())
    }, [dispatch])

    return <div className='footer'>
        <div className='footerWrapper container'>
            <div className={`footer-links ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}>
                <h4 className='footer-links-title'>{t('containers_app_components_footer_follow')}</h4>
                {socialLinks.map(item => <SocialLink key={item._id} link={item} />)}
            </div>
            <p className='footer-description'>
                COPYRIGHT © {new Date().getFullYear()} TIMELYCODE - All Rigths Reserved
            </p>
        </div>
    </div>
}

export default Footer
