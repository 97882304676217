import classnames from 'classnames';
import React from 'react';
import './imageUpload.scss';

type ImageUpload = {
  value?: string | null | ArrayBuffer;
  onChange?(data: string): void;
  setNewImage?: any;
  backgroundImage?: string;
  backgroundSize?: string;
  maxMb?: number;
  className?: string;
  circleStyle?: boolean;
};

const ImageUpload: React.FunctionComponent<ImageUpload> = props => {
  const { value, onChange, backgroundImage, backgroundSize, className, circleStyle, setNewImage } = props;

  const selectFile = () => {
    setNewImage();
  };

  return (
    <div className={classnames('imageUpload', className, { 'imageUpload-circle': circleStyle })}>
      {value && (
        <div className={'imageUpload-uploaded'}>
          <img alt='uploaded' className={'imageUpload-uploaded-img'} src={value as string} />
          <div className={'imageUpload-options'}>
            {circleStyle ? (
              <>
                <img onClick={selectFile} className='imageUpload-options-add' src='/icons/ic_edit.svg' alt='ic_edit' />
                <img
                  onClick={() => onChange && onChange('')}
                  className='imageUpload-options-remove'
                  src='/icons/ic_remove.svg'
                  alt='ic_remove'
                />
              </>
            ) : (
              <>
                <p className={'editIcons editIcons-edit'} onClick={() => selectFile()}>
                  <img src='/icons/edit_icon_black.svg' alt='edit_icon_black' />
                </p>
                {/* <p className={"editIcons editIcons-delete"} onClick={() => onChange && onChange('')} /> */}
              </>
            )}
          </div>
        </div>
      )}
      {!value && <p>Select image</p>}
      {!value && (
        <div className={'imageUpload-select'} style={{ backgroundImage, backgroundSize }} onClick={selectFile}>
          {!circleStyle && <p className={'imageUpload-select-icon editIcons editIcons-add'} />}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
