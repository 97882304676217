import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChangeLanguage } from '../../App/store/selectors';
import { actions } from '../store';
import { TimelycodeStore } from '../../../shared/types';
import { useTranslation } from 'react-i18next';

const Summary = () => {
  const changeLanguage = useSelector(getChangeLanguage());
  const {
    success: { summary }
  } = useSelector((state: TimelycodeStore) => state);
  const {
    i18n: { language }
  } = useTranslation();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(actions.getSummarySuccess.request());
  }, [dispatch]);

  return (
    <section className='summaryText section'>
      {
        <div
          className={`companyDescription ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}
          // @ts-ignore
          dangerouslySetInnerHTML={{ __html: summary[`description_${language}`] }}
        />
      }
    </section>
  );
};

export default Summary;
