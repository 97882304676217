import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteMessage } from '../../../../Careers/store/actions';
import { IMessage } from '../interfaces/IMessage';
import { SingleMessage } from '../singleMessage/SingleMessage';
import './messagePreview.scss';

interface IMessagePreviewProps {
  item: IMessage;
}

const MessagePreview: React.FC<IMessagePreviewProps> = ({ item }) => {
  const [openMode, setOpenMode] = useState(false);
  const dispatch = useDispatch();

  const closeMessage = () => {
    setOpenMode(false);
  };

  const openMessage = () => {
    setOpenMode(true);
  };

  const deleteCurrentMessage = (id: string) => {
    const yes = window.confirm('Do you really want to delete the message?');
    if (yes) dispatch(deleteMessage.request({ id }));
  };

  return (
    <div className='message-preview' key={item._id}>
      <img src='/icons/delete.png' alt='delete' className='delete-ico' onClick={() => deleteCurrentMessage(item._id)} />
      <p className='name' onClick={openMessage}>
        Message from {item.name}
      </p>
      {openMode && <SingleMessage item={item} closeMessage={closeMessage} />}
    </div>
  );
};

export { MessagePreview };
