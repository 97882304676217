export default {
	shared_components_listEditComponents: {
		alert: 'File is to large for upload',
		title_label: 'Title',
		title_placeholder: 'Title',
		text_label: 'Text',
		text_placeholder: 'Text',
		title_label_en: 'Title in English',
		title_placeholder_en: 'Title in English',
		text_label_en: 'Text in English',
		text_placeholder_en: 'Text in English',
		title_label_ukr: 'Title in Ukrainian',
		title_placeholder_ukr: 'Title in Ukrainian',
		text_label_ukr: 'Text in Ukrainian',
		text_placeholder_ukr: 'Text in Ukrainian',
	},
	shared_constants_messageText: {
		SAVED_SUCCESSFULLY: 'Saved successfully!',
		DELETED_SUCCESSFULLY: 'Title',
		ERROR: 'Title',
	},
	SAVED_SUCCESSFULLY: 'Saved successfully!',
	DELETED_SUCCESSFULLY: 'Deleted successfully',
	ERROR: 'ERROR',
	shared_components_editToggle_edit: 'EDIT',
	address_ukr: 'Адреса',
	address_en: 'Address',
	city_ukr: 'Місто',
	city_en: 'City',
	country_ukr: 'Держава',
	country_en: 'Country',
	title_ukr: 'Назва',
	title_en: 'Title',
	text_ukr: 'Текст',
	text_en: 'Text',
	link: 'Link',
	type: 'Type',
	optional: 'Optional',
	required_skills: 'Required skills',
	must_have_skills: 'Must have skills',
	position: 'Position',
	text: 'Text',



}
