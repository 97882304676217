import React from 'react';
import { IAddress } from '../../interfaces';
import './address.scss';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

interface IContactProps {
  address: IAddress;
}

const Address: React.FC<IContactProps> = props => {
  const { address } = props;
  const { title, address: location, city, country } = address;
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <div key={address._id} className='address i-location'>
      <h4 className='address-title'>{get(address, `title_${language}`, title)}</h4>
      <p className='address-address'>{get(address, `address_${language}`, location)}</p>
      <p className='address-city'>{get(address, `city_${language}`, city)}</p>
      <h4 className='address-country'>{get(address, `country_${language}`, country)}</h4>
    </div>
  );
};

export default Address;
