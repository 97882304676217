import React, { useLayoutEffect, useState } from 'react';
import { SectionTitle } from '../../shared/components';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { baseSlickSettings } from '../../shared/constants';
import './successStories.scss';
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './store';
import { TimelycodeStore } from '../../shared/types';
import { ISuccessStory } from './interfaces';
import SuccessDetails from './components/SuccessDetails';
import { useHistory } from 'react-router-dom';
import { landingAnchors, routesNames } from '../App/constants';
import Summary from './components/Summary';
import { InTouch } from '../Careers';

const SuccessStories: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const {
    success: { stories }
  } = useSelector((state: TimelycodeStore) => state);
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  if (language === 'ukr' && window.location.pathname === '/success') {
    history.push({ pathname: routesNames.HOME, hash: landingAnchors.COMPANY }); // It is not supported on ukrainian for now
  }

  useLayoutEffect(() => {
    dispatch(actions.getSuccessStories.request());
  }, [dispatch]);

  const getRelevantStory = () => {
    return stories.find(story => story._id === selectedId);
  };

  const handleSelectedId = (id: string) => {
    setSelectedId(id);
    setTimeout(() => {
      const el = document.getElementById('successDetails');
      if (el) el.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  return (
    <div className='lookingFor section container' id='success_stories'>
      {!isAdmin && <Summary />}
      <SectionTitle title={t('success_title')} preLine={t('success_title_perline')} />
      <div className='services-list'>
        <Slider {...{ ...baseSlickSettings, slidesToShow: 3 }}>
          {!!stories.length &&
            stories.map((item: ISuccessStory) => {
              const { title, image_base64, _id } = item;
              return (
                <div id={`vacancyPreview`} className={`vacancyPreview`} onClick={() => handleSelectedId(_id)} key={_id}>
                  <div className='imageWrapper'>
                    <img className='vacancyPreview-icon' src={image_base64} alt={'title'} />
                  </div>
                  <LinesEllipsis
                    className='vacancyPreview-title'
                    maxLine='4'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                    text={title}
                  />
                  <p className='vacancyPreview-view'>
                    {t('containers_careers_components_lookingFor_vacancyPrev_details')}
                  </p>
                </div>
              );
            })}
        </Slider>
        {<SuccessDetails details={getRelevantStory()} />}
      </div>
      {!isAdmin && <InTouch screen='success' />}
    </div>
  );
};

export default SuccessStories;
