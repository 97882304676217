import { RefObject, useLayoutEffect, useState } from "react";
import { clickListeners } from "../../utils";

export interface IRefObjectContains {
  contains: (target: EventTarget | null) => boolean;
}

function useOutsideClick<T extends IRefObjectContains>(ref: RefObject<T>, exclude?: (Element | null | undefined)[]) {
  const [isOutside, setOutside] = useState<boolean>();

  const setOutsideValue = () => {
    setOutside(!isOutside);
  };

  useLayoutEffect(() => {
    const listener = (event: TouchEvent | MouseEvent) => {
      const isOutside = !ref?.current?.contains(event.target) ?? false;
      const filteredExclude = exclude?.filter(Boolean) ?? [];
      if (filteredExclude.length) {
        const isOutsideWithExclude = filteredExclude.some(e => !e?.contains(event.target as Node) ?? true) && isOutside;
        setOutside(isOutsideWithExclude);
      } else {
        setOutside(isOutside);
      }
    };
    clickListeners(document, listener);
    return () => clickListeners(document, listener, true);
  }, [ref, exclude]);

  return { isOutside, setOutsideValue };
}

export default useOutsideClick;
