import produce from "immer";
import { Reducer } from "redux";
import { IAppState } from "../interfaces";
import { AppActionTypes } from "./constants";

const initialState: IAppState = {
  socialLinks: [],
  showNotification: {
    state: false,
    message: '',
    type: ''
  },
  changeLanguage: true
};

export const AppReducer: Reducer<IAppState> = (state: IAppState = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.GET_SOCIAL_LINKS_SUCCESS:
      return produce(state, nextState => {
        nextState.socialLinks = action.payload;
      });
    case AppActionTypes.SHOW_NOTIFICATION:
      return produce(state, nextState => {
        nextState.showNotification = action.payload
      })
    case AppActionTypes.HIDE_NOTIFICATION:
      return produce(state, nextState => {
        nextState.showNotification = {
          state: false,
          message: '',
          type: ''
        }
      })
    case AppActionTypes.LANGUAGE_PROCESS:
      return produce(state, nextState => {
        nextState.changeLanguage = action.payload
      })
    default:
      return state;
  }
};
