import { AnyAction } from 'redux';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { Type } from '../../App/constants/notificationType';
import api from '../api/api';
import { actions } from './';
import { SuccessStoriesTypes } from './constants';
import notification from '../../../shared/utils/notification';
import i18n from 'i18next';

function* createSuccessStory({ payload }: AnyAction) {
  try {
    const res = yield call(api.createSuccessStory, payload);
    if (res) {
      const successStories = yield call(api.getSuccessStories);
      yield put(actions.getSuccessStories.success(successStories));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* getSuccessStories() {
  try {
    const successStories = yield call(api.getSuccessStories);
    yield put(actions.getSuccessStories.success(successStories));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getSuccessStories.failure());
  }
}

function* updateSuccessStory({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateSuccessStory, payload);
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS);
      const successStories = yield call(api.getSuccessStories);
      yield put(actions.getSuccessStories.success(successStories));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getSuccessStories.failure());
  }
}

function* deleteSuccessStory({ payload }: AnyAction) {
  try {
    const res = yield call(api.deleteSuccessStory, payload._id);
    if (res) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS);
      const successStories = yield call(api.getSuccessStories);
      yield put(actions.getSuccessStories.success(successStories));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* getSummarySuccess() {
  try {
    const summary = yield call(api.getSummarySuccess);
    yield put(actions.getSummarySuccess.success(summary));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getSuccessStories.failure());
  }
}

function* updateSummarySuccess({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateSummarySuccess, payload);
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS);
      const summarySuccess = yield call(api.getSummarySuccess);
      yield put(actions.getSummarySuccess.success(summarySuccess));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getSuccessStories.failure());
  }
}

export default function* careersSaga() {
  yield takeLatest(SuccessStoriesTypes.CREATE_SUCCESS_REQUEST, createSuccessStory);
  yield takeLatest(SuccessStoriesTypes.GET_SUCCESS_REQUEST, getSuccessStories);
  yield takeLatest(SuccessStoriesTypes.UPDATE_SUCCESS_REQUEST, updateSuccessStory);
  yield takeLatest(SuccessStoriesTypes.DELETE_SUCCESS_REQUEST, deleteSuccessStory);
  yield takeLatest(SuccessStoriesTypes.GET_SUMMARY_SUCCESS_REQUEST, getSummarySuccess);
  yield takeLatest(SuccessStoriesTypes.UPDATE_SUMMARY_SUCCESS_REQUEST, updateSummarySuccess);
}
