export default {
	containers_dashboard_containers_careers_components_lookingFor_addresses: 'Addresses:',
	containers_dashboard_containers_landing_components_companyDescription: {
		title_placeholder_en: 'Title in English',
		title_label_en: 'Title in English',
		description_placeholder_en: 'Description in English',
		description_label_en: 'Description in English',
		title_placeholder_ukr: 'Title in Ukrainian',
		title_label_ukr: 'Title in Ukrainian',
		description_placeholder_ukr: 'Description in Ukrainian',
		description_label_ukr: 'Description in Ukrainian',
		submit: 'Submit'
	},
	containers_dashboard_containers_landing_components_contacts: {
		title: 'Contacts',
		links: 'Footer links:',
		address: 'Addresses:',
		contacts: 'Contacts:',
	},
	containers_dashboard_containers_messages_singleMess: {
		messFrom: 'Message from:',
		received: 'Received:',
		interesting: 'I\'m interesting in vacancy of:',
		contacts: 'Contacts:',
	},
}
