import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {EditableList, EditToggle, ListEditComponent} from '../../../../../../shared/components';
import { TYPES } from '../../../../../../shared/constants';
import {Technologies as TechnologiesDetails} from '../../../../../Landing/components/Technologies'
import {getTechnologies} from '../../../../../Landing/store/selectors';
import './technology.scss'

interface ITechnologyProps {
    

}

export const Technologies: React.FC<ITechnologyProps> = props => {
    const [editMode, setEditMode] = useState(false);
    const technologies = useSelector(getTechnologies())

    return <div>
        <EditToggle
            editModeOpened={editMode}
            onClick={() => setEditMode(true)}
            onCancel={() => setEditMode(false)}
            onSave={() => {
            }}
        />
        {editMode ?
            <EditableList
                type={TYPES.TECHNOLOGIES}
                onDelete={() => {}}
                onSave={() => {}}
                list={technologies}
                editComponent={ListEditComponent}
            /> : <TechnologiesDetails/>}
    </div>
}

export default Technologies
