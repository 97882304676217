import classnames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Scene } from '../../../../shared/components/Scene';
import { landingAnchors, routesNames } from '../../constants';
import { INavItem } from '../../interfaces';
import './header.scss';
import { NavItem } from './NavItem';
import { useSelector } from 'react-redux';
import { getChangeLanguage } from '../../store/selectors';
import { useTranslation } from 'react-i18next';

const classListActions = (type: 'remove' | 'add', elem: HTMLElement, classes: string[]) => {
  if (type === 'add') {
    classes.forEach(c => {
      !elem.classList.contains(c) && elem.classList.add(c);
    });
  } else if (type === 'remove') {
    classes.forEach(c => {
      elem.classList.contains(c) && elem.classList.remove(c);
    });
  }
};

interface IHeaderProps {
  defaultFixed?: boolean;
  navItems: INavItem[];
}

const Header: React.FC<IHeaderProps> = props => {
  const { defaultFixed, navItems } = props;
  const changeLanguage = useSelector(getChangeLanguage());
  const [sceneToGo, setSceneToGo] = useState(false);

  const headerRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const {
    i18n: { language }
  } = useTranslation();

  useEffect(() => {
    const {
      location: { pathname, hash }
    } = history;
    if (pathname === '/' && !hash) {
      if (language === 'ukr') {
        history.push({ pathname: routesNames.HOME, hash: landingAnchors.COMPANY });
      } else history.push(routesNames.SUCCESS);
    }
  }, [history]);

  const navOnTopListener = () => {
    const { current } = headerRef;
    if (current) {
      const headerTop = current.getBoundingClientRect().top;
      if (headerTop <= -150) {
        classListActions('add', current, ['onTop']);
        if (headerTop <= -194) {
          setSceneToGo(true);
          classListActions('add', current, ['fixed']);
          classListActions('remove', current, ['onTop']);
        } else {
          setSceneToGo(false);
          classListActions('add', current, ['onTop']);
          classListActions('remove', current, ['fixed']);
        }
      } else {
        setSceneToGo(false);
        classListActions('remove', current, ['onTop', 'fixed']);
      }
    }
  };

  useLayoutEffect(() => {
    !defaultFixed && navOnTopListener();
    if (!defaultFixed) {
      window.addEventListener('scroll', navOnTopListener);
      return () => {
        window.removeEventListener('scroll', navOnTopListener);
      };
    }
  }, [defaultFixed]);

  const goHome = () => {
    history.push({ pathname: routesNames.HOME, hash: landingAnchors.COMPANY });
  };

  return (
    <>
      <header className={classnames('header container', { defaultFixed })} ref={headerRef}>
        <img
          className={`header-bigLogo ${changeLanguage ? 'shake-left_start' : 'shake-left_end'}`}
          src='/images/big_logo.svg'
          alt='big_logo'
        />
        <nav className='header-nav' ref={navRef}>
          <div className='header-smallLogo' onClick={goHome}>
            <div className={`header-smallLogo-title ${changeLanguage ? 'shake-left_start' : 'shake-left_end'}`}>
              timelycode
            </div>
          </div>
          <div
            className={`header-navItemsWrapper ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}
          >
            {navItems.map((item, index) => (
              <NavItem key={index} item={item} />
            ))}
          </div>
        </nav>
      </header>
      <Scene go={defaultFixed || sceneToGo} />
    </>
  );
};

export default Header;
