import React, {FunctionComponent, ReactElement, useEffect} from "react";
import {Route, RouteProps} from "react-router-dom";
import {HashRoute} from '../HashRoute';

interface IPageProps extends RouteProps {
    title?: string;
    children?: ReactElement
    hash?: string | string[]
}

const Page: FunctionComponent<IPageProps> = props => {
    const {title = "", component: Component, hash, children, ...rest} = props;

    useEffect(() => {
        document.title = title ? `timelycode | ${title}` : "timelycode";
    });

    return !!hash ? <HashRoute hash={hash} component={Component} {...rest}>{children}</HashRoute> :
        <Route component={Component}  {...rest} >{children}</Route>;
};

export default Page;
