import config from '../../config';

const BASE_URL = config.baseApiUrl;

export default {
  AUTH: {
    LOGIN: `${BASE_URL}/auth/login`
  },
  CONTACTS: {
    CREATE_SOCIAL_LINK: `${BASE_URL}/socialLinks/create`,
    SOCIAL_LINKS: `${BASE_URL}/socialLinks`,
    DELETE_SOCIAL_LINK: `${BASE_URL}/socialLinks/delete`
  },
  CAREERS: {
    IN_TOUCH: `${BASE_URL}/inTouch`,
    CREATE_VACANCY: `${BASE_URL}/vacancies/create`,
    VACANCIES: `${BASE_URL}/vacancies`,
    DELETE_VACANCY: `${BASE_URL}/vacancies/delete`,
    OFFER: `${BASE_URL}/offer`,
    CREATE_OFFER: `${BASE_URL}/offer/create`,
    DELETE_OFFER: `${BASE_URL}/offer/delete`,
    MESSAGES: `${BASE_URL}/messages`,
    MESSAGES_DELETE: `${BASE_URL}/messages`
  },
  SUCCESS: {
    STORIES: `${BASE_URL}/successStories`,
    STORY_DELETE: `${BASE_URL}/successStories/`,
    SUMMARY: `${BASE_URL}/successStories/summary`
  },
  LANDING: {
    COMPANY_DESCRIPTION: `${BASE_URL}/companyDescription`,
    CREATE_CONTACT: `${BASE_URL}/contacts/create`,
    CONTACTS: `${BASE_URL}/contacts`,
    DELETE_CONTACT: `${BASE_URL}/contacts/delete`,
    CREATE_ADDRESS: `${BASE_URL}/addresses/create`,
    ADDRESSES: `${BASE_URL}/addresses`,
    DELETE_ADDRESS: `${BASE_URL}/addresses/delete`,
    SERVICES: `${BASE_URL}/services`,
    CREATE_SERVICE: `${BASE_URL}/services/create`,
    DELETE_SERVICE: `${BASE_URL}/services/delete`,
    TECHNOLOGIES: `${BASE_URL}/technologies`,
    CREATE_TECHNOLOGY: `${BASE_URL}/technologies/create`,
    DELETE_TECHNOLOGY: `${BASE_URL}/technologies/delete`
  }
};
