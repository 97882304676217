import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {privateRoutesMap, routesNames} from '../../constants';
import {Page} from '../Page';

const privateRoutes = privateRoutesMap.map((route, index) => <Page key={index} {...route} />)

const PrivateRoutes: React.FunctionComponent = () => {

  return (
      <Switch>
        {privateRoutes}
        <Route component={() => <Redirect to={routesNames.DASHBOARD_LOGIN} />} />
      </Switch>
  );
};
export default PrivateRoutes;
