export const in_touch_template = {
  name: 'Some Name',
  position: 'CEO',
  image_base64: '',
  contacts: [
    {
      text: '+380 63 113 6069',
      link: 'tel: +380631136069',
      type: 'phone'
    },
    {
      text: '+380 63 113 6069',
      link: 'https://wa.me/+380631136069',
      type: 'whatsapp'
    },
    {
      text: 'sales@timelycode.com',
      link: 'mailto:sales@timelycode.com',
      type: 'email'
    },
    {
      link: 'https://www.linkedin.com/company/timelycode/',
      text: 'LinkedIn',
      type: 'linkedin'
    },
    {
      text: '13 Pekarska str., 79008, Lviv, Ukraine',
      type: 'location'
    }
  ]
};
