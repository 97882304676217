import React, { CSSProperties } from 'react';
import './list.scss';

interface IList {
  title: string;
  list: ({ link: string; text: string } | string)[];
  style?: CSSProperties;
}

const List: React.FC<IList> = props => {
  const { title, list, style, children } = props;
  return (
    <div className='listWrapper' style={style}>
      <h4 className='listWrapper-title'>{title}</h4>
      <ul className='listWrapper-list'>
        {list.map((i, index) => (
          <li key={index} className='listWrapper-list-item'>
            {typeof i === 'string' ? (
              <p>{i}</p>
            ) : (
              <a href={i.link} rel='noopener noreferrer' target='_blank'>
                {i.text}
              </a>
            )}
          </li>
        ))}
        {children}
      </ul>
    </div>
  );
};

export default List;
