import React, { useState } from 'react';
import { ImageUpload } from '../ImageUpload';
import { StyledInput } from '../StyledInput';
import './listEditComponent.scss';
import selectFiles from 'select-files';
import { useDispatch } from 'react-redux';
import { TYPES } from '../../constants';
import { updateService, updateTechnologies } from '../../../containers/Landing/store/actions';
import { updateInTouchData, updateOffer, updateVacancies } from '../../../containers/Careers/store/actions';
import { useTranslation } from 'react-i18next';
import { IVacancy } from '../../../containers/Careers/interfaces';
import { cloneDeep, set } from 'lodash';
import { updateSuccessStory } from '../../../containers/SuccessStories/store/actions';
import { POSITIONS } from '../../constants/positions';

interface IListEditComponentProps {
  item: any;
  onCancelEdit: any;
  type: string;
}

const langExceptions = ['contacts', 'position', 'name', 'technologies', 'link'];

const ListEditComponent: React.FC<IListEditComponentProps> = props => {
  const { item, onCancelEdit, type } = props;
  const [localItem, setLocalItem] = useState(item);
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { image_base64, title_en, text_en, title_ukr, text_ukr, _id, title, text, presentation, ...rest } = localItem;

  const rest_keys = rest ? Object.keys(rest) : undefined;
  const dispatch = useDispatch();

  const setNewImage = () => {
    selectFiles({ multiple: false, accept: 'image/*', capture: false }).then(photo => {
      let img: File | any;
      if (photo && photo[0]) {
        img = photo[0];
      }
      if (img.size > 100000) {
        return alert(t('shared_components_listEditComponents.alert'));
      } else {
        const readerPicture = new FileReader();
        readerPicture.readAsDataURL(img);
        readerPicture.onload = () => {
          setLocalItem((prevState: object) => ({ ...prevState, image_base64: readerPicture.result }));
        };
      }
    });
  };

  const changeProperties = (key: string, value: any) => {
    setLocalItem((prevState: any) => {
      const clonedData = cloneDeep(prevState);
      set(clonedData, key, value);
      return clonedData;
    });
  };

  const onDeleteRow = (key: string, index: number) => {
    setLocalItem((prevState: IVacancy) => {
      const clonedData = cloneDeep(prevState);
      clonedData[key].splice(index, 1);
      return clonedData;
    });
  };

  const onAddRow = (key: string, _id: string) => {
    setLocalItem((prevState: any) => ({ ...prevState, [key]: [...prevState[key], 'some text'] }));
  };

  const saveData = () => {
    switch (type) {
      case TYPES.SERVICES:
        dispatch(updateService.request(localItem));
        break;
      case TYPES.TECHNOLOGIES:
        dispatch(updateTechnologies.request(localItem));
        break;
      case TYPES.OFFERS:
        dispatch(updateOffer.request(localItem));
        break;
      case TYPES.VACANCIES:
        dispatch(updateVacancies.request(localItem));
        break;
      case TYPES.IN_TOUCH:
        dispatch(updateInTouchData.request(localItem));
        break;
      case TYPES.SUCCESS:
        dispatch(updateSuccessStory.request(localItem));
        break;
    }
    onCancelEdit(_id);
  };

  return (
    <div className='listEditComponent'>
      <ImageUpload value={image_base64} setNewImage={setNewImage} />
      <div className='listEditComponent-inputs'>
        {(localItem.hasOwnProperty(`title_${language}`) || localItem.hasOwnProperty('title')) && (
          <StyledInput
            value={localItem[`title_${language}`] || localItem.title}
            name={localItem.hasOwnProperty('title') ? 'title' : `title_${language}`}
            placeholder={t(`shared_components_listEditComponents.title_placeholder_${language}`)}
            label={t(`shared_components_listEditComponents.title_label_${language}`)}
            required
            onChange={(key: string, value: any) => changeProperties(key, value)}
            updatedData={localItem}
          />
        )}
        {(localItem.hasOwnProperty(`text_${language}`) || localItem.hasOwnProperty('text')) && (
          <StyledInput
            value={localItem[`text_${language}`] || localItem.text}
            name={localItem.hasOwnProperty('text') ? 'text' : `text_${language}`}
            placeholder={t(`shared_components_listEditComponents.text_placeholder_${language}`)}
            label={t(`shared_components_listEditComponents.text_label_${language}`)}
            multiline
            required
            onChange={(key: string, value: any) => changeProperties(key, value)}
            updatedData={localItem}
          />
        )}
        {rest_keys && rest_keys.length ? (
          rest_keys.reverse().map(element => {
            if (!langExceptions.includes(element) && !element.includes(`_${language}`)) return null;
            if (element === 'position') {
              return (
                <div key={element} className='styledInput-inputWrap'>
                  <select
                    name={element}
                    value={localItem[element]}
                    className='styledInput-input'
                    onChange={e => changeProperties(element, e.target.value)}
                  >
                    {Object.values(POSITIONS).map(pos => (
                      <option key={pos}>{pos}</option>
                    ))}
                  </select>
                </div>
              );
            }
            return (
              <div key={element}>
                {Array.isArray(rest[element]) ? (
                  rest[element].map((description: any, index: number) => {
                    return element === 'contacts' ? (
                      Object.keys(description).map((input, i) => (
                        <StyledInput
                          name={input}
                          label={t(input)}
                          placeholder={t(input)}
                          required
                          index={index}
                          key={element + i}
                          contacts={true}
                          onChange={(key: string, value: any) => changeProperties(key, value)}
                          value={description[input]}
                          updatedData={localItem}
                        />
                      ))
                    ) : (
                      <div key={element + index} className='flex'>
                        <StyledInput
                          value={description}
                          name={element}
                          placeholder={t(description)}
                          label={t(element)}
                          required
                          index={index}
                          updatedData={localItem}
                          className={'styledInput-w75'}
                          onChange={(key: string, value: any) => changeProperties(key, value)}
                        />
                        <div className='button-wrapper'>
                          <button className='button-action'>
                            <img
                              className='contain'
                              src='/icons/delete.png'
                              alt='delete'
                              onClick={() => onDeleteRow(element, index)}
                            />
                          </button>
                          <button className='button-action'>
                            <img src='/icons/plus.svg' alt='delete' onClick={() => onAddRow(element, _id)} />
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <StyledInput
                    value={rest[element]}
                    name={element}
                    placeholder={t(element)}
                    label={t(element)}
                    required
                    key={element}
                    updatedData={localItem}
                    onChange={(key: string, value: any) => changeProperties(key, value)}
                  />
                )}
              </div>
            );
          })
        ) : (
          <div></div>
        )}
        {type === TYPES.SUCCESS && (
          <StyledInput
            value={presentation ? `Change ${title} presentation` : ''}
            name={'presentation'}
            placeholder={'presentation'}
            label={'presentation'}
            key={'presentation'}
            updatedData={localItem}
            type='file'
            onChange={(key: string, value: any) => changeProperties(key, value)}
          />
        )}
      </div>
      <div className='editableList-preview-actions'>
        <button onClick={saveData}>
          <img src='/icons/checked.png' alt='save' />
        </button>
        <button
          onClick={() => {
            onCancelEdit(_id);
          }}
        >
          <img src='/icons/cancel.png' alt='cancel' />
        </button>
      </div>
    </div>
  );
};

export default ListEditComponent;
