import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionTitle } from '../../../../shared/components/SectionTitle';
import { scrollTo } from '../../../../shared/utils';
import { IVacancy } from '../../interfaces';
import { actions, selectors } from '../../store';
import './lookingFor.scss';
import { VacancyDetails } from './VacancyDetails';
import { VacancyPreview } from './VacancyPreview';
import { useTranslation } from 'react-i18next';
import { ITranslation } from '../../../Landing/interfaces';
import { get } from 'lodash';

const LookingFor: React.FC = () => {
  const [detailsItemActiveId, setDetailsItemActiveId] = useState<string | undefined>();
  const vacancyListRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const vacancies = useSelector(selectors.getVacancies());

  const {
    i18n: { language }
  } = useTranslation() as ITranslation;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getVacancies.request());
  }, [dispatch]);

  const isVacancyActive = (_id: string) => _id === detailsItemActiveId;

  const showDetails = (_id: string) => {
    if (isVacancyActive(_id)) setDetailsItemActiveId(undefined);
    else {
      setDetailsItemActiveId(_id);
      const { current } = vacancyListRef;
      if (current) {
        const elementToFocus = current.querySelector(`#vacancyPreview-${_id}`);
        elementToFocus && scrollTo(elementToFocus as HTMLDivElement, 75);
      }
    }
  };

  return (
    <div className='lookingFor section'>
      <SectionTitle
        title={t('containers_careers_components_lookingFor.title')}
        preLine={t('containers_careers_components_lookingFor.preLine')}
      />
      <div
        ref={vacancyListRef}
        className={classnames('lookingFor-vacanciesList', `lookingFor-vacanciesList-${vacancies.length}`)}
      >
        {vacancies.map((vacancy: IVacancy) => (
          <React.Fragment key={vacancy._id}>
            <VacancyPreview
              id={vacancy._id}
              title={vacancy[`title_${language}`] || vacancy.title}
              active={isVacancyActive(vacancy._id)}
              icon={vacancy.image_base64}
              showDetails={showDetails}
            />
            <VacancyDetails
              id={vacancy._id}
              title={vacancy[`title_${language}`] || vacancy.title}
              active={isVacancyActive(vacancy._id)}
              required_skills={get(vacancy, `required_skills_${language}`)}
              optional={get(vacancy, `optional_${language}`)}
              must_have_skills={get(vacancy, `must_have_skills_${language}`, [])}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default LookingFor;
