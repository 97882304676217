import React from 'react';
import classnames from 'classnames';
import './alertStyle.scss'

interface IAlertComponentProps {
    message: string,
    className: string
}

export const AlertComponent: React.FC<IAlertComponentProps> = (props) => {
    const { message, className } = props;

    return (
        <div className='background'>
            <div className={classnames('background-content', className)}>
                <div className='background-text'>{message}</div>
            </div>
        </div>
    )
}
