import { createSelector } from "reselect";
import { TimelycodeStore } from "../../../shared/types";

const selectLanding = (state: TimelycodeStore) => state.landing;

export const getContacts = () => createSelector(selectLanding, state => state.contacts);
export const getAddresses = () => createSelector(selectLanding, state => state.addresses);
export const getServices = () => createSelector(selectLanding, state => state.services);
export const getTechnologies = () => createSelector(selectLanding, state => state.technologies);
export const getCompanyDescription = () => createSelector(selectLanding, state => state.companyDescription);
