import classnames from 'classnames';
import React from 'react';
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis';
import './vacancyPreview.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getChangeLanguage } from '../../../../App/store/selectors';

interface IVacancyPreview {
  id: string;
  icon: string;
  title: string;
  active: boolean;
  showDetails(id: string): void;
}

const VacancyPreview: React.FC<IVacancyPreview> = props => {
  const { id, active, icon, title, showDetails } = props;
  const { t } = useTranslation();
  const changeLanguage = useSelector(getChangeLanguage());
  return (
    <div
      id={`vacancyPreview-${id}`}
      className={classnames(
        'vacancyPreview',
        { active },
        `${changeLanguage ? 'flip-in-hor-top_start' : 'flip-in-hor-top_end'}`
      )}
      onClick={() => showDetails(id)}
    >
      <img className='vacancyPreview-icon' src={icon} alt={title} />
      <LinesEllipsis
        className='vacancyPreview-title'
        maxLine='4'
        ellipsis='...'
        trimRight
        basedOn='letters'
        text={title}
      />
      <p className='vacancyPreview-view'>{t('containers_careers_components_lookingFor_vacancyPrev_details')}</p>
    </div>
  );
};

export default VacancyPreview;
