export enum LandingActionTypes {
  GET_COMPANY_DESCRIPTION_REQUEST = "@@LANDING/GET_COMPANY_DESCRIPTION_REQUEST",
  GET_COMPANY_DESCRIPTION_SUCCESS = "@@LANDING/GET_COMPANY_DESCRIPTION_SUCCESS",
  GET_COMPANY_DESCRIPTION_FAILURE = "@@LANDING/GET_COMPANY_DESCRIPTION_FAILURE",

  UPDATE_COMPANY_DESCRIPTION_REQUEST = "@@LANDING/UPDATE_COMPANY_DESCRIPTION_REQUEST",
  UPDATE_COMPANY_DESCRIPTION_SUCCESS = "@@LANDING/UPDATE_COMPANY_DESCRIPTION_SUCCESS",
  UPDATE_COMPANY_DESCRIPTION_FAILURE = "@@LANDING/UPDATE_COMPANY_DESCRIPTION_FAILURE",

  CREATE_CONTACTS_REQUEST = "@@LANDING/CREATE_CONTACTS_REQUEST",
  CREATE_CONTACTS_SUCCESS = "@@LANDING/CREATE_CONTACTS_SUCCESS",
  CREATE_CONTACTS_FAILURE = "@@LANDING/CREATE_CONTACTS_FAILURE",
  
  GET_CONTACTS_REQUEST = "@@LANDING/GET_CONTACTS_REQUEST",
  GET_CONTACTS_SUCCESS = "@@LANDING/GET_CONTACTS_SUCCESS",
  GET_CONTACTS_FAILURE = "@@LANDING/GET_CONTACTS_FAILURE",
  
  UPDATE_CONTACTS_REQUEST = "@@LANDING/UPDATE_CONTACTS_REQUEST",
  UPDATE_CONTACTS_SUCCESS = "@@LANDING/UPDATE_CONTACTS_SUCCESS",
  UPDATE_CONTACTS_FAILURE = "@@LANDING/UPDATE_CONTACTS_FAILURE",
  
  DELETE_CONTACTS_REQUEST = "@@LANDING/DELETE_CONTACTS_REQUEST",
  DELETE_CONTACTS_SUCCESS = "@@LANDING/DELETE_CONTACTS_SUCCESS",
  DELETE_CONTACTS_FAILURE = "@@LANDING/DELETE_CONTACTS_FAILURE",

  CREATE_ADDRESSES_REQUEST = "@@LANDING/CREATE_ADDRESSES_REQUEST",
  CREATE_ADDRESSES_SUCCESS = "@@LANDING/CREATE_ADDRESSES_SUCCESS",
  CREATE_ADDRESSES_FAILURE = "@@LANDING/CREATE_ADDRESSES_FAILURE",
  
  GET_ADDRESSES_REQUEST = "@@LANDING/GET_ADDRESSES_REQUEST",
  GET_ADDRESSES_SUCCESS = "@@LANDING/GET_ADDRESSES_SUCCESS",
  GET_ADDRESSES_FAILURE = "@@LANDING/GET_ADDRESSES_FAILURE",
  
  UPDATE_ADDRESSES_REQUEST = "@@LANDING/UPDATE_ADDRESSES_REQUEST",
  UPDATE_ADDRESSES_SUCCESS = "@@LANDING/UPDATE_ADDRESSES_SUCCESS",
  UPDATE_ADDRESSES_FAILURE = "@@LANDING/UPDATE_ADDRESSES_FAILURE",
  
  DELETE_ADDRESSES_REQUEST = "@@LANDING/DELETE_ADDRESSES_REQUEST",
  DELETE_ADDRESSES_SUCCESS = "@@LANDING/DELETE_ADDRESSES_SUCCESS",
  DELETE_ADDRESSES_FAILURE = "@@LANDING/DELETE_ADDRESSES_FAILURE",

  CREATE_SERVICES_REQUEST = "@@LANDING/CREATE_SERVICES_REQUEST",
  CREATE_SERVICES_SUCCESS = "@@LANDING/CREATE_SERVICES_SUCCESS",
  CREATE_SERVICES_FAILURE = "@@LANDING/CREATE_SERVICES_FAILURE",
  
  GET_SERVICES_REQUEST = "@@LANDING/GET_SERVICES_REQUEST",
  GET_SERVICES_SUCCESS = "@@LANDING/GET_SERVICES_SUCCESS",
  GET_SERVICES_FAILURE = "@@LANDING/GET_SERVICES_FAILURE",

  UPDATE_SERVICES_REQUEST = "@@LANDING/UPDATE_SERVICES_REQUEST",
  UPDATE_SERVICES_SUCCESS = "@@LANDING/UPDATE_SERVICES_SUCCESS",
  UPDATE_SERVICES_FAILURE = "@@LANDING/UPDATE_SERVICES_FAILURE",
  
  DELETE_SERVICES_REQUEST = "@@LANDING/DELETE_SERVICES_REQUEST",
  DELETE_SERVICES_SUCCESS = "@@LANDING/DELETE_SERVICES_SUCCESS",
  DELETE_SERVICES_FAILURE = "@@LANDING/DELETE_SERVICES_FAILURE",

  CREATE_TECHNOLOGIES_REQUEST = "@@LANDING/CREATE_TECHNOLOGIES_REQUEST",
  CREATE_TECHNOLOGIES_SUCCESS = "@@LANDING/CREATE_TECHNOLOGIES_SUCCESS",
  CREATE_TECHNOLOGIES_FAILURE = "@@LANDING/CREATE_TECHNOLOGIES_FAILURE",
  
  GET_TECHNOLOGIES_REQUEST = "@@LANDING/GET_TECHNOLOGIES_REQUEST",
  GET_TECHNOLOGIES_SUCCESS = "@@LANDING/GET_TECHNOLOGIES_SUCCESS",
  GET_TECHNOLOGIES_FAILURE = "@@LANDING/GET_TECHNOLOGIES_FAILURE",

  UPDATE_TECHNOLOGIES_REQUEST = "@@LANDING/UPDATE_TECHNOLOGIES_REQUEST",
  UPDATE_TECHNOLOGIES_SUCCESS = "@@LANDING/UPDATE_TECHNOLOGIES_SUCCESS",
  UPDATE_TECHNOLOGIES_FAILURE = "@@LANDING/UPDATE_TECHNOLOGIES_FAILURE",
 
  DELETE_TECHNOLOGIES_REQUEST = "@@LANDING/DELETE_TECHNOLOGIES_REQUEST",
  DELETE_TECHNOLOGIES_SUCCESS = "@@LANDING/DELETE_TECHNOLOGIES_SUCCESS",
  DELETE_TECHNOLOGIES_FAILURE = "@@LANDING/DELETE_TECHNOLOGIES_FAILURE",

}
