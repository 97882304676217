import containers_app_en from '../../containers/App/i18n/en';
import containers_auth_en from '../../containers/Auth/i18n/en';
import containers_careers_en from '../../containers/Careers/i18n/en';
import containers_dashboard_en from '../../containers/Dashboard/i18n/en';
import containers_landing_en from '../../containers/Landing/i18n/en';
import success_stories from '../../containers/SuccessStories/i18n/en';
import shared_en from '../../shared/i18n/en';

export default {
  path_flag: '/icons/en.svg',
  ...containers_app_en,
  ...containers_auth_en,
  ...containers_careers_en,
  ...containers_dashboard_en,
  ...containers_landing_en,
  ...shared_en,
  ...success_stories
};
