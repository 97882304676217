import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { EditToggle, ListEditComponent } from '../../../../../../shared/components';
import { TYPES } from '../../../../../../shared/constants';
import { InTouch as ITouchDetails } from '../../../../../Careers';
import { getInTOuchData } from '../../../../../Careers/store/selectors';

const InTouch: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const in_touch = useSelector(getInTOuchData());
  const recruiter = in_touch.find(person => person.position === 'Recruiter');

  return (
    <div>
      <EditToggle
        editModeOpened={editMode}
        onClick={() => setEditMode(true)}
        onCancel={() => setEditMode(false)}
        onSave={() => {}}
      />
      {editMode ? (
        <ListEditComponent type={TYPES.IN_TOUCH} item={recruiter} onCancelEdit={() => setEditMode(false)} />
      ) : (
        <ITouchDetails screen='careers' />
      )}
    </div>
  );
};

export default InTouch;
