export default {
	containers_landing_components_contacts: {
		title: 'Контакти',
		preLine: 'наші',
		messSent: 'Ваше повідомлення успішно надіслано. Дякуємо!',
		fillReqFields: 'Будь ласка, заповніть необхідні поля',
		name_placeholder: 'Ваше ім\'я *',
		email_placeholder: 'Пошта *',
		phone_placeholder: 'Телефон *',
		message_placeholder: 'Повідомлення *',
		submit: 'Підтвердити',
	},
	containers_landing_components_services: {
		title: 'послуги',
		preLine: 'наші'
	},
	containers_landing_components_technologies: 'технології',
}