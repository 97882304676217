export const success_story_template = {
  image_base64: '',
  project_summary_en: 'Summary project',
  project_summary_ukr: 'Проект',
  country_en: 'USA',
  country_ukr: 'США',
  status_en: 'Completed',
  status_ukr: 'Завершено',
  team_en: '2 engineers',
  team_ukr: '2 розробника',
  technologies: 'Node.js, Firebase, Gatsby, Moment library, payment systems.',
  link: 'https://somesite.com/',
  presentation: '',
  title: 'Project',
  text_en: 'Description of the project',
  text_ukr: 'Опис проекту'
};
