import React from 'react';
import { useSelector } from 'react-redux';
import { AlertComponent } from '../../../../../shared/components';
import { getNotification } from '../../../../App/store/selectors';
import {CompanyDescription, Contacts, Services, Technology} from '../components';
import './landingContainer.scss'

interface ILandingContainerProps {

}

const LandingContainer: React.FC<ILandingContainerProps> = props => {
    const {state, message, type} = useSelector(getNotification())

    return <div className='landingContainer container section'>
        <CompanyDescription />
        <Services />
        <Technology />
        <Contacts />
        {state && <AlertComponent message={message} className={type} />}
    </div>
}

export default LandingContainer
