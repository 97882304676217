import React, { useState } from 'react';
import { StyledInput } from '../../../../../../../shared/components';
import './contactsEditComponent.scss';
import '../../../../../../../shared/components/EditableList/editableList.scss';
import { useDispatch } from 'react-redux';
import { updateAddresses, updateContacts } from '../../../../../../Landing/store/actions';
import { TYPES } from '../../../../../../../shared/constants';
import { updateSocialLinks } from '../../../../../../App/store/actions';
import { useTranslation } from 'react-i18next';

interface IContactsEditComponentProps {
  item: any;
  type: string;
  onCancelEdit: any;
}

const ContactsEditComponent: React.FC<IContactsEditComponentProps> = props => {
  const dispatch = useDispatch();

  let { item, onCancelEdit, type } = props;
  const [localItem, setLocalItem] = useState(item);
  const {
    t,
    i18n: { language }
  } = useTranslation();

  const inputsArray = () => {
    const { link, type, _id, ...rest } = localItem;
    const correctRest = Object.keys(rest).filter(el => el.includes(`_${language}`));
    return link ? [...correctRest, 'link', 'type', 'text'] : correctRest;
  };

  const onChangeHandler = (key: string, value: any) => {
    setLocalItem((prev: object) => ({ ...prev, [key]: value }));
  };

  const onSave = () => {
    switch (type) {
      case TYPES.ADDRESSES:
        dispatch(updateAddresses.request(localItem));
        break;
      case TYPES.CONTACTS:
        dispatch(updateContacts.request(localItem));
        break;
      case TYPES.SOCIAL_LINKS:
        dispatch(updateSocialLinks.request(localItem));
        break;
    }
    onCancelEdit(localItem._id);
  };

  return (
    <div className='contactsEditComponent'>
      <div className='editableList-preview-actions jc_end'>
        <button onClick={onSave}>
          <img src='/icons/checked.png' alt='save' />
        </button>
        <button onClick={() => onCancelEdit(item._id)}>
          <img src='/icons/cancel.png' alt='cancel' />
        </button>
      </div>
      {inputsArray().map((input, index) => (
        <div key={index}>
          <StyledInput
            name={input}
            label={t(input) || input}
            placeholder={t(input)}
            required
            onChange={(key: any, value: any) => onChangeHandler(key, value)}
            value={localItem[input]}
            updatedData={localItem}
          />
        </div>
      ))}
    </div>
  );
};

export default ContactsEditComponent;
