import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { App } from './containers/App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import './styles/index.scss';
import './i18n/index';

if (process.env.REACT_APP_ENV === 'dev') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, { trackHooks: true });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
