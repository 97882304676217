import { createAsyncAction } from "typesafe-actions";
import {IAddress, IContact} from '../../../shared/interfaces';
import { RequestWithCallback } from "../../../shared/interfaces/sagas";
import {ICompanyDescription, IService, ITechnology} from '../interfaces';
import { LandingActionTypes } from "./constants";

export const getCompanyDescription = createAsyncAction(
  LandingActionTypes.GET_COMPANY_DESCRIPTION_REQUEST,
  LandingActionTypes.GET_COMPANY_DESCRIPTION_SUCCESS,
  LandingActionTypes.GET_COMPANY_DESCRIPTION_FAILURE,
)<undefined, ICompanyDescription, undefined>();

export const updateCompanyDescription = createAsyncAction(
  LandingActionTypes.UPDATE_COMPANY_DESCRIPTION_REQUEST,
  LandingActionTypes.UPDATE_COMPANY_DESCRIPTION_SUCCESS,
  LandingActionTypes.UPDATE_COMPANY_DESCRIPTION_FAILURE,
)<RequestWithCallback<any>, any, string>();

export const getContacts = createAsyncAction(
  LandingActionTypes.GET_CONTACTS_REQUEST,
  LandingActionTypes.GET_CONTACTS_SUCCESS,
  LandingActionTypes.GET_CONTACTS_FAILURE,
)<undefined, IContact[], undefined>();

export const createContact = createAsyncAction(
  LandingActionTypes.CREATE_CONTACTS_REQUEST,
  LandingActionTypes.CREATE_CONTACTS_SUCCESS,
  LandingActionTypes.CREATE_CONTACTS_FAILURE,
)<object, undefined, undefined>();

export const updateContacts = createAsyncAction(
  LandingActionTypes.UPDATE_CONTACTS_REQUEST,
  LandingActionTypes.UPDATE_CONTACTS_SUCCESS,
  LandingActionTypes.UPDATE_CONTACTS_FAILURE,
  )<RequestWithCallback<any>, any, any>();

  export const deleteContact = createAsyncAction(
  LandingActionTypes.DELETE_CONTACTS_REQUEST,
  LandingActionTypes.DELETE_CONTACTS_SUCCESS,
  LandingActionTypes.DELETE_CONTACTS_FAILURE,
  )<RequestWithCallback<any>, any, any>();

export const createAddress = createAsyncAction(
  LandingActionTypes.CREATE_ADDRESSES_REQUEST,
  LandingActionTypes.CREATE_ADDRESSES_SUCCESS,
  LandingActionTypes.CREATE_ADDRESSES_FAILURE,
)<object, undefined, undefined>();

export const getAddresses = createAsyncAction(
  LandingActionTypes.GET_ADDRESSES_REQUEST,
  LandingActionTypes.GET_ADDRESSES_SUCCESS,
  LandingActionTypes.GET_ADDRESSES_FAILURE,
)<undefined, IAddress[], undefined>();

export const updateAddresses = createAsyncAction(
  LandingActionTypes.UPDATE_ADDRESSES_REQUEST,
  LandingActionTypes.UPDATE_ADDRESSES_SUCCESS,
  LandingActionTypes.UPDATE_ADDRESSES_FAILURE,
  )<RequestWithCallback<any>, any, any>();

  export const deleteAddress = createAsyncAction(
  LandingActionTypes.DELETE_ADDRESSES_REQUEST,
  LandingActionTypes.DELETE_ADDRESSES_SUCCESS,
  LandingActionTypes.DELETE_ADDRESSES_FAILURE,
  )<RequestWithCallback<any>, any, any>();

export const getServices = createAsyncAction(
  LandingActionTypes.GET_SERVICES_REQUEST,
  LandingActionTypes.GET_SERVICES_SUCCESS,
  LandingActionTypes.GET_SERVICES_FAILURE,
)<undefined, IService[], undefined>();

export const updateService = createAsyncAction(
  LandingActionTypes.UPDATE_SERVICES_REQUEST,
  LandingActionTypes.UPDATE_SERVICES_SUCCESS,
  LandingActionTypes.UPDATE_SERVICES_FAILURE,
)<RequestWithCallback<any>, any, any>();

export const getTechnologies = createAsyncAction(
  LandingActionTypes.GET_TECHNOLOGIES_REQUEST,
  LandingActionTypes.GET_TECHNOLOGIES_SUCCESS,
  LandingActionTypes.GET_TECHNOLOGIES_FAILURE,
)<undefined, ITechnology[], undefined>();

export const createTechnology = createAsyncAction(
  LandingActionTypes.CREATE_TECHNOLOGIES_REQUEST,
  LandingActionTypes.CREATE_TECHNOLOGIES_SUCCESS,
  LandingActionTypes.CREATE_TECHNOLOGIES_FAILURE,
  )<RequestWithCallback<any>, any, any>();

export const updateTechnologies = createAsyncAction(
  LandingActionTypes.UPDATE_TECHNOLOGIES_REQUEST,
  LandingActionTypes.UPDATE_TECHNOLOGIES_SUCCESS,
  LandingActionTypes.UPDATE_TECHNOLOGIES_FAILURE,
)<RequestWithCallback<any>, any, any>();

export const deleteTechnology = createAsyncAction(
  LandingActionTypes.DELETE_TECHNOLOGIES_REQUEST,
  LandingActionTypes.DELETE_TECHNOLOGIES_SUCCESS,
  LandingActionTypes.DELETE_TECHNOLOGIES_FAILURE,
)<RequestWithCallback<any>, any, any>();

export const createService = createAsyncAction(
  LandingActionTypes.CREATE_SERVICES_REQUEST,
  LandingActionTypes.CREATE_SERVICES_SUCCESS,
  LandingActionTypes.CREATE_SERVICES_FAILURE,
)<RequestWithCallback<any>, any, any>();

export const deleteService = createAsyncAction(
  LandingActionTypes.DELETE_SERVICES_REQUEST,
  LandingActionTypes.DELETE_SERVICES_SUCCESS,
  LandingActionTypes.DELETE_SERVICES_FAILURE,
)<RequestWithCallback<any>, any, any>();