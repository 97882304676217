export default {
	shared_components_listEditComponents: {
		alert: 'Файл занадто великий для завантаження',
		title_label: 'Назва',
		title_placeholder: 'Назва',
		text_label: 'Текст',
		text_placeholder: 'Текст',
		title_label_en: 'Назва англійською',
		title_placeholder_en: 'Назва англійською ',
		text_label_en: 'Текст англійською',
		text_placeholder_en: 'Текст англійською',
		title_label_ukr: 'Назва українською',
		title_placeholder_ukr: 'Назва українською',
		text_label_ukr: 'Текст українською',
		text_placeholder_ukr: 'Текст українською',
	},
	shared_constants_messageText: {
		SAVED_SUCCESSFULLY: 'Успішно збережено!',
		DELETED_SUCCESSFULLY: 'Назва',
		ERROR: 'Назва',
	},
	SAVED_SUCCESSFULLY: 'Успішно збережено!',
	DELETED_SUCCESSFULLY: 'Видалення успішне',
	ERROR: 'ПОМИЛКА',
	shared_components_editToggle_edit: 'РЕДАГУВАТИ',
	address_ukr: 'Адреса',
	address_en: 'Address',
	city_ukr: 'Місто',
	city_en: 'City',
	country_ukr: 'Держава',
	country_en: 'Country',
	title_ukr: 'Назва',
	title_en: 'Title',
	text_ukr: 'Текст',
	text_en: 'Text',
	link: 'Посилання',
	type: 'Тип',
	optional: 'Додатково',
	required_skills: 'Необхідні навички',
	must_have_skills: "Обов'язко володіти навичками",
	position: 'Позиція',
	text: 'Текст',
}
