import classnames from 'classnames';
import React, {CSSProperties} from 'react';
import {CustomArrowProps} from 'react-slick';
import './arrow.scss'

interface IArrowProps extends CustomArrowProps {
    styles?: CSSProperties;
    direction?: 'top' | 'left' | 'right' | 'bottom'
}

const Arrow: React.FC<IArrowProps> = props => {
    const {styles, direction, onClick} = props;
    return <div onClick={onClick} className={classnames('arrow', direction)} style={styles} />
}

export default Arrow
