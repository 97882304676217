import classnames from 'classnames';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Address, Contact, SectionTitle } from '../../../../shared/components';
import { sendMessage } from '../../../Careers/store/actions';
import { actions, selectors } from '../../store';
import './contacts.scss';
import { useTranslation } from 'react-i18next';
import { getChangeLanguage } from '../../../App/store/selectors';

const prepareInitialValues = () => ({
  name: '',
  email: '',
  phone: '',
  message: ''
});

const validationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  message: Yup.string().required()
});

const Contacts: React.FC = () => {
  const [initialValues, setInitialValues] = useState(prepareInitialValues());
  const [formAlertState, setFormAlertState] = useState<'error' | 'success' | undefined>();
  const addresses = useSelector(selectors.getAddresses());
  const contacts = useSelector(selectors.getContacts());
  const { t } = useTranslation();
  const changeLanguage = useSelector(getChangeLanguage());

  const dispatch = useDispatch();

  useEffect(() => setInitialValues(prepareInitialValues), []);

  useEffect(() => {
    dispatch(actions.getContacts.request());
    dispatch(actions.getAddresses.request());
  }, [dispatch]);

  const checkOnFillForm = (values: any, errors: any) => {
    if (!Object.values(values).every(Boolean) || Object.keys(errors).length) setFormAlertState('error');
  };

  const sendData = (data: any) => {
    dispatch(sendMessage.request(data));
    setInitialValues(prepareInitialValues);
  };

  return (
    <section className='contacts section container'>
      <SectionTitle
        title={t('containers_landing_components_contacts.title')}
        preLine={t('containers_landing_components_contacts.preLine')}
      />
      <div className={`contactsWrapper ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            // setFormAlertState('success')
            sendData(values);
            resetForm();
          }}
        >
          {({ errors, values, touched, handleChange, handleSubmit }) => {
            const { name, email, message, phone } = values;
            return (
              <div className='contacts-form'>
                <p className={classnames('contacts-form-alert', formAlertState)}>
                  {formAlertState === 'success' && t('containers_landing_components_contacts.messSent')}
                  {formAlertState === 'error' && t('containers_landing_components_contacts.fillReqFields')}
                  <span className='closeArrow' onClick={() => setFormAlertState(undefined)}>
                    ×
                  </span>
                </p>
                <input
                  onChange={handleChange}
                  value={name}
                  name='name'
                  placeholder={t('containers_landing_components_contacts.name_placeholder')}
                  type='text'
                  className={classnames({ error: touched.name && errors.name })}
                />
                <input
                  onChange={handleChange}
                  value={email}
                  name='email'
                  placeholder={t('containers_landing_components_contacts.email_placeholder')}
                  type='text'
                  className={classnames({ error: touched.email && errors.email })}
                />
                <input
                  onChange={handleChange}
                  value={phone}
                  name='phone'
                  placeholder={t('containers_landing_components_contacts.phone_placeholder')}
                  type='tel'
                  className={classnames({ error: touched.email && errors.email })}
                />
                <textarea
                  onChange={handleChange}
                  value={message}
                  name='message'
                  placeholder={t('containers_landing_components_contacts.message_placeholder')}
                  className={classnames({ error: touched.message && errors.message })}
                />
                <button
                  className='contacts-form-submitBtn'
                  type='submit'
                  onClick={() => {
                    checkOnFillForm(values, errors);
                    handleSubmit();
                  }}
                >
                  {t('containers_landing_components_contacts.submit')}
                </button>
              </div>
            );
          }}
        </Formik>
        <div className='contacts-list'>
          {addresses.map(item => (
            <Address key={item._id} address={item} />
          ))}
          {contacts.map(item => (
            <Contact key={item._id} contact={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Contacts;
