import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {actions, selectors} from '../../store';
import './companyDescription.scss'
import {useTranslation} from "react-i18next";
import {getChangeLanguage} from "../../../App/store/selectors";

const CompanyDescription: React.FC = () => {
    const data = {...useSelector(selectors.getCompanyDescription())}
    const dispatch = useDispatch();
    const changeLanguage = useSelector(getChangeLanguage())
    const {i18n: {language}} = useTranslation();

    // @ts-ignore
    const title = data[`title_${language}`];
    // @ts-ignore
    const description = data[`description_${language}`];
    useEffect(() => {
        dispatch(actions.getCompanyDescription.request())
    }, [dispatch])

    return <section className='companyDescriptionWrapper section'>
            {title && description && <div
				className={`companyDescription ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}
				dangerouslySetInnerHTML={{__html: `${title}${description}`}}
			/>}
        </section>

}

export default CompanyDescription;
