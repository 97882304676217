import { DashboardCareerContainer, Messages } from '../../Dashboard';
import { DashboardLandingContainer } from '../../Dashboard/containers/Landing';
import DashboardSuccess from '../../Dashboard/containers/Success/DashboardSuccess';
import { IRoute } from '../interfaces';
import { privateRoutesNames } from './routesNames';

export const privateRoutesMap: IRoute[] = [
  {
    path: privateRoutesNames.DASHBOARD,
    exact: true,
    component: DashboardLandingContainer
  },
  {
    path: privateRoutesNames.DASHBOARD_CAREERS,
    exact: true,
    component: DashboardCareerContainer
  },
  {
    path: privateRoutesNames.DASHBOARD_SUCCESS,
    exact: true,
    component: DashboardSuccess
  },
  {
    path: privateRoutesNames.DASHBOARD_MESSAGES,
    exact: true,
    component: Messages
  }
];
