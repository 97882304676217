import produce from 'immer';
import { Reducer } from 'redux';
import { ICareersState } from '../interfaces';
import { CareersActionTypes } from './constants';

const initialState: ICareersState = {
  vacancies: [],
  offer: [],
  messages: []
};

export const CareersReducer: Reducer<ICareersState> = (state: ICareersState = initialState, action) => {
  switch (action.type) {
    case CareersActionTypes.GET_OFFER_SUCCESS:
      return produce(state, nextState => {
        nextState.offer = action.payload;
      });
    case CareersActionTypes.GET_IN_TOUCH_SUCCESS:
      return produce(state, nextState => {
        nextState.inTouch = action.payload;
      });
    case CareersActionTypes.GET_VACANCIES_SUCCESS:
      return produce(state, nextState => {
        nextState.vacancies = action.payload;
      });
    case CareersActionTypes.GET_MESSAGE_SUCCESS:
      return produce(state, nextState => {
        nextState.messages = action.payload;
      });
    default:
      return state;
  }
};
