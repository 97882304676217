import {createAction, createAsyncAction} from "typesafe-actions";
import {ISocialLink} from '../../../shared/interfaces';
import { RequestWithCallback } from "../../../shared/interfaces/sagas";
import { AppActionTypes } from "./constants";

export const createSocialLink = createAsyncAction(
  AppActionTypes.CREATE_SOCIAL_LINKS_REQUEST,
  AppActionTypes.CREATE_SOCIAL_LINKS_SUCCESS,
  AppActionTypes.CREATE_SOCIAL_LINKS_FAILURE,
)<object, undefined, undefined>();

export const getSocialLinks = createAsyncAction(
  AppActionTypes.GET_SOCIAL_LINKS_REQUEST,
  AppActionTypes.GET_SOCIAL_LINKS_SUCCESS,
  AppActionTypes.GET_SOCIAL_LINKS_FAILURE,
)<undefined, ISocialLink[], undefined>();

export const updateSocialLinks = createAsyncAction(
  AppActionTypes.UPDATE_SOCIAL_LINKS_REQUEST,
  AppActionTypes.UPDATE_SOCIAL_LINKS_SUCCESS,
  AppActionTypes.UPDATE_SOCIAL_LINKS_FAILURE,
)<RequestWithCallback<any>, any, undefined>();

export const deleteSocialLink = createAsyncAction(
  AppActionTypes.DELETE_SOCIAL_LINKS_REQUEST,
  AppActionTypes.DELETE_SOCIAL_LINKS_SUCCESS,
  AppActionTypes.DELETE_SOCIAL_LINKS_FAILURE,
)<RequestWithCallback<any>, any, undefined>();

export const langProcess = createAction(
    AppActionTypes.LANGUAGE_PROCESS, (bool: boolean) => bool)();

