export default {
	containers_landing_components_contacts: {
		title: 'contacts',
		preLine: 'our',
		messSent: 'Your message was sent successfully.\nThanks!',
		fillReqFields: 'Please fill in the required fields',
		name_placeholder: 'Your Name *',
		email_placeholder: 'Email *',
		phone_placeholder: 'Phone *',
		message_placeholder: 'Message *',
		submit: 'Submit',
	},
	containers_landing_components_services: {
		title: 'services',
		preLine: 'our'
	},
	containers_landing_components_technologies: 'technology',
}