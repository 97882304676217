import produce from 'immer';
import { Reducer } from 'redux';
import { successStoriesState } from '../interfaces';
import { SuccessStoriesTypes } from './constants';

const initialState: successStoriesState = {
  stories: [],
  summary: {
    _id: '',
    description_en: '',
    description_ukr: ''
  }
};

export const SuccessReducer: Reducer<successStoriesState> = (state: successStoriesState = initialState, action) => {
  switch (action.type) {
    case SuccessStoriesTypes.GET_SUCCESS_SUCCESS:
      return produce(state, nextState => {
        nextState.stories = action.payload;
      });
    case SuccessStoriesTypes.GET_SUMMARY_SUCCESS_SUCCESS:
      return produce(state, nextState => {
        nextState.summary = action.payload;
      });
    default:
      return state;
  }
};
