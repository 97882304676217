import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import { AlertComponent } from '../../../shared/components';
import { landingAnchors } from '../../App/constants';
import { getNotification } from '../../App/store/selectors';
import { CompanyDescription, Contacts, Services, Technologies } from '../components';

const LandingContainer: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const landingRef = useRef<HTMLDivElement>(null);

  const { state, type, message } = useSelector(getNotification());
  useEffect(() => {
    return () => {
      const hashItems = document.querySelectorAll('.hashItemWrapper.active');
      hashItems.forEach(i => i.classList.remove('active'));
    };
  }, []);

  useLayoutEffect(() => {
    const { hash, state, pathname } = location;
    const { from = '' } = { ...(state as {}) };
    const { action } = history;
    action !== 'REPLACE' &&
      scroller.scrollTo(hash, {
        duration: 800,
        smooth: pathname === from
      });
  }, [location, history]);

  return (
    <div ref={landingRef} id='landingContainer'>
      <Element name={landingAnchors.COMPANY}>
        <CompanyDescription />
      </Element>
      <Element name={landingAnchors.SERVICES}>
        <Services />
      </Element>
      <Element name={landingAnchors.TECHNOLOGY}>
        <Technologies />
      </Element>
      {state && <AlertComponent message={message} className={type} />}
      <Element name={landingAnchors.CONTACT}>
        <Contacts />
      </Element>
    </div>
  );
};

export default LandingContainer;
