export enum SuccessStoriesTypes {
  GET_SUCCESS_REQUEST = '@@SUCCESS/GET_SUCCESS_REQUEST',
  GET_SUCCESS_SUCCESS = '@@SUCCESS/GET_SUCCESS_SUCCESS',
  GET_SUCCESS_FAILURE = '@@CAREERS/GET_SUCCESS_FAILURE',

  CREATE_SUCCESS_REQUEST = '@@SUCCESS/CREATE_SUCCESS_REQUEST',
  CREATE_SUCCESS_SUCCESS = '@@SUCCESS/CREATE_SUCCESS_SUCCESS',
  CREATE_SUCCESS_FAILURE = '@@SUCCESS/CREATE_SUCCESS_FAILURE',

  UPDATE_SUCCESS_REQUEST = '@@SUCCESS/UPDATE_SUCCESS_REQUEST',
  UPDATE_SUCCESS_SUCCESS = '@@SUCCESS/UPDATE_SUCCESS_SUCCESS',
  UPDATE_SUCCESS_FAILURE = '@@SUCCESS/UPDATE_SUCCESS_FAILURE',

  DELETE_SUCCESS_REQUEST = '@@SUCCESS/DELETE_SUCCESS_REQUEST',
  DELETE_SUCCESS_SUCCESS = '@@SUCCESS/DELETE_SUCCESS_SUCCESS',
  DELETE_SUCCESS_FAILURE = '@@SUCCESS/DELETE_SUCCESS_FAILURE',

  GET_SUMMARY_SUCCESS_REQUEST = '@@SUCCESS/GET_SUMMARY_SUCCESS_REQUEST',
  GET_SUMMARY_SUCCESS_SUCCESS = '@@SUCCESS/GET_SUMMARY_SUCCESS_SUCCESS',
  GET_SUMMARY_SUCCESS_FAILURE = '@@CAREERS/GET_SUMMARY_SUCCESS_FAILURE',

  UPDATE_SUMMARY_SUCCESS_REQUEST = '@@SUCCESS/UPDATE_SUMMARY_SUCCESS_REQUEST',
  UPDATE_SUMMARY_SUCCESS_SUCCESS = '@@SUCCESS/UPDATE_SUMMARY_SUCCESS_SUCCESS',
  UPDATE_SUMMARY_SUCCESS_FAILURE = '@@SUCCESS/UPDATE_SUMMARY_SUCCESS_FAILURE'
}
