import { combineReducers } from 'redux';
import { AppReducer } from '../containers/App/store';
import { AuthReducer } from '../containers/Auth/store';
import { CareersReducer } from '../containers/Careers/store';
import { LandingReducer } from '../containers/Landing/store';
import { SuccessReducer } from '../containers/SuccessStories/store';

const appReducer = combineReducers({
  app: AppReducer,
  careers: CareersReducer,
  landing: LandingReducer,
  auth: AuthReducer,
  success: SuccessReducer
});

export default (state: any, action: any) => {
  return appReducer(state, action);
};
