import { applyMiddleware, compose, createStore, Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import freeze from "redux-freeze";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducer";
import sagas from "./saga";

const sagaMiddleware = createSagaMiddleware();
let middleware: Array<Middleware>;
let composer: Function;

if (process.env.NODE_ENV === "development") {
  middleware = [freeze, sagaMiddleware];
  composer = composeWithDevTools({ trace: true, traceLimit: 25 });
} else {
  middleware = [sagaMiddleware];
  composer = compose;
}

function configureStore() {
  const store = createStore(rootReducer, undefined, composer(applyMiddleware(...middleware)));

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept(() => store.replaceReducer(rootReducer));
  }

  return { store };
}

const store = configureStore().store;

export default store;
