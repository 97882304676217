export default {
	containers_careers_components_inTouch: {
		title: 'ЗВ\'ЯЗОК',
		preLine: 'ЗВОРОТНІЙ'
	},
	containers_careers_components_lookingFor: {
		title: 'ШУКАЄМО',
		preLine: 'МИ'
	},
	containers_careers_components_lookingFor_vacancyDetails: {
		req_skills: 'Необхідні навички:',
		optional: 'Додатково:',
		must_have_skills: "Обов'язко володіти навичками:"
	},
	containers_careers_components_lookingFor_vacancyDetails_vacancyForm: {
		invalid_mail: 'Не коректна пошта',
		req_mail: 'Вимагається!',
		req_file: 'Файл є обов\'язковим полем для заповнення',
		onDrop_error: 'Непідтримуваний формат файлу',
		name_placeholder: 'Ваше ім\`я *',
		email_placeholder: 'Пошта *',
		phone_placeholder: 'Телефон *',
		upload: 'Завантажити резюме',
		attached: 'Ви прикріпили:',
		file_here: 'Ви можете перетянути свій файл сюди',
		send: 'Надіслати'
	},
	containers_careers_components_offer: {
		title: 'пропонуємо',
		preLine: 'ми'
	},
	containers_careers_components_lookingFor_vacancyPrev_details: 'ПОКАЗАТИ ДЕТАЛІ',
}
