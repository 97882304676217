import React from 'react';
import './editToggle.scss'
import {useTranslation} from "react-i18next";

interface IEditToggleProps {
    editModeOpened: boolean,
    onClick(): void,
    onCancel(): void,
    onSave(): void
}

const EditToggle: React.FC<IEditToggleProps> = props => {
    const {editModeOpened, onClick, onCancel} = props;
    const { t } = useTranslation()
    return <div className='editToggle'>
        {!editModeOpened ?
            <button className='editToggle-editBtn' onClick={onClick}>{t('shared_components_editToggle_edit')}</button> :
            <div className='editToggle-actions'>
                {/* <img onClick={onSave} className='editToggle-actions-action' src="/icons/checked.png" alt="save"/> */}
                <img onClick={onCancel} className='editToggle-actions-action' src="/icons/cancel.png" alt="cancel"/>
            </div>
        }
    </div>
}

export default EditToggle
