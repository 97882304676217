import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { EditableList, EditToggle, ListEditComponent } from '../../../../../../shared/components';
import { TYPES } from '../../../../../../shared/constants';
import { Offer as OfferDetails } from '../../../../../Careers';
import { selectors } from '../../../../../Careers/store';
import '../../../Landing/components/CompanyDescription/_companyDescription.scss'


const Offers: React.FC = () => {
    const [editMode, setEditMode] = useState(false);
    const offerList = useSelector(selectors.getOffer())

    return (
        <div className="dashboardCompanyDescription">
             <EditToggle
            editModeOpened={editMode}
            onClick={() => setEditMode(true)}
            onCancel={() => setEditMode(false)}
            onSave={() => {
            }}
        />
        {editMode ?
            <EditableList
                type={TYPES.OFFERS}
                onDelete={() => { }}
                onSave={()=> {}}
                list={offerList}
                editComponent={ListEditComponent}
            /> : <OfferDetails />}
        </div>
    )
}

export default Offers;