import { INavItem } from '../interfaces';
import { landingAnchors, privateRoutesNames, routesNames } from './routesNames';

// const styleVariables = require('../../../styles/variables.scss')
//text міняти в i18n тут це значення для перекладу
const NAV_ITEMS: INavItem[] = [
  {
    text: 'company',
    path: routesNames.HOME,
    hash: landingAnchors.COMPANY
  },
  {
    text: 'services',
    path: routesNames.HOME,
    hash: landingAnchors.SERVICES
  },
  {
    text: 'technology',
    path: routesNames.HOME,
    hash: landingAnchors.TECHNOLOGY
  },
  {
    text: 'careers',
    path: routesNames.CAREERS
  },
  {
    text: 'success stories',
    path: routesNames.SUCCESS
  },
  {
    text: 'contact',
    path: routesNames.HOME,
    hash: landingAnchors.CONTACT
  },
  {
    text: 'lang',
    path: 'path_flag',
    hash: 'lang'
  }
];

const DASHBOARD_NAV_ITEMS: INavItem[] = [
  {
    text: 'landing',
    path: privateRoutesNames.DASHBOARD
  },
  {
    text: 'careers',
    path: privateRoutesNames.DASHBOARD_CAREERS
  },
  {
    text: 'success stories',
    path: privateRoutesNames.DASHBOARD_SUCCESS
  },
  {
    text: 'messages',
    path: privateRoutesNames.DASHBOARD_MESSAGES
  },
  {
    text: 'lang',
    path: 'path_flag',
    hash: 'lang'
  },
  {
    text: 'logout',
    path: routesNames.HOME,
    hash: landingAnchors.CONTACT
  }
];

export { NAV_ITEMS, DASHBOARD_NAV_ITEMS };
