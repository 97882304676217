export default {
	nav_items: {
		company: 'Company',
		services: 'Services',
		technology: 'Technology',
		careers: 'Careers',
		contact: 'Contact',
		landing: 'Landing',
		messages: 'Messages',
		logout: 'Logout',
		'success stories': 'Success Stories',
	},
	containers_app_components_footer_follow: 'Follow us:',
}
