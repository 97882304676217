import classnames from 'classnames';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { sendMessage } from '../../../../store/actions';
import './vacancyForm.scss';
import { getChangeLanguage } from '../../../../../App/store/selectors';

interface IVacancyForm {
  id: string;
  title: string;
}

const allowedExtensions = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/*',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint'
];

interface FileFormValues {
  id: string;
  vacancy: string;
  name: string;
  email: string;
  phone: string;
  file?: {
    filename: string;
    file_base_64: string;
  };
}

const prepareInitialValues = (id: string, vacancy: string): FileFormValues => ({
  id,
  vacancy,
  name: '',
  email: '',
  phone: '',
  file: undefined
});

const VacancyForm: React.FC<IVacancyForm> = props => {
  const { id, title } = props;
  const [initialValues, setInitialValues] = useState(prepareInitialValues(id, title));
  const dropzoneInputContainerRef = useRef<HTMLDivElement>(null);
  const dropZoneRef = useRef<DropzoneRef>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const changeLanguage = useSelector(getChangeLanguage());

  const validationSchema = Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
    email: Yup.string()
      .email(t('containers_careers_components_lookingFor_vacancyDetails_vacancyForm.invalid_mail'))
      .required(t('containers_careers_components_lookingFor_vacancyDetails_vacancyForm.req_mail')),
    file: Yup.object()
      .nullable()
      .required(t('containers_careers_components_lookingFor_vacancyDetails_vacancyForm.req_file'))
  });

  useEffect(() => {
    setInitialValues(prepareInitialValues(id, title));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const setDragEnterClass = (enter: boolean) => {
    const { current } = dropzoneInputContainerRef;
    if (current) {
      enter ? current.classList.add('dragEnter') : current.classList.remove('dragEnter');
    }
  };

  const onDrop = useCallback(
    (files: File[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
      if (!files || (files && !files[0])) return;

      const reader = new FileReader();

      reader.onload = function (e) {
        setFieldValue('file', {
          file_base_64: e?.target?.result,
          filename: files[0].name
        });
      };

      reader.readAsDataURL(files[0]);
    },
    []
  );

  const sendData = (data: any) => {
    dispatch(sendMessage.request(data));
    setInitialValues(prepareInitialValues(id, title));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm, setFieldValue }) => {
        sendData(values);
        resetForm();
      }}
    >
      {({ values, setFieldTouched, setFieldError, setFieldValue, handleChange, handleSubmit, touched, errors }) => {
        const { email, name, file, phone } = values;
        return (
          <Dropzone
            ref={dropZoneRef}
            accept={allowedExtensions}
            multiple={false}
            noClick={true}
            onDragEnter={() => setDragEnterClass(true)}
            onDragLeave={() => setDragEnterClass(false)}
            onDropAccepted={() => {
              setFieldTouched('file');
              setDragEnterClass(false);
            }}
            onDropRejected={() => {
              setFieldTouched('file');
              setFieldError(
                'file',
                t('containers_careers_components_lookingFor_vacancyDetails_vacancyForm.onDrop_error')
              );
              setDragEnterClass(false);
            }}
            onDrop={acceptedFile => onDrop(acceptedFile, setFieldValue)}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className={`vacancyForm ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}
                {...getRootProps()}
              >
                <input
                  value={name}
                  name='name'
                  placeholder={t(
                    'containers_careers_components_lookingFor_vacancyDetails_vacancyForm.name_placeholder'
                  )}
                  type='text'
                  onChange={handleChange}
                  className={classnames('vacancyForm-input', { error: touched.name && errors.name })}
                />
                {errors.name && touched.name && <p>{errors.name}</p>}
                <input
                  value={email}
                  name='email'
                  placeholder={t(
                    'containers_careers_components_lookingFor_vacancyDetails_vacancyForm.email_placeholder'
                  )}
                  type='email'
                  onChange={handleChange}
                  className={classnames('vacancyForm-input', { error: touched.email && errors.email })}
                />
                {errors.email && touched.email && <p>{errors.email}</p>}
                <input
                  value={phone}
                  name='phone'
                  placeholder={t(
                    'containers_careers_components_lookingFor_vacancyDetails_vacancyForm.phone_placeholder'
                  )}
                  type='tel'
                  onChange={handleChange}
                  className={classnames('vacancyForm-input', { error: touched.phone && errors.phone })}
                />
                {errors.phone && touched.phone && <p>{errors.phone}</p>}
                <section
                  className={classnames('vacancyForm-file', { error: touched.file && errors.file })}
                  ref={dropzoneInputContainerRef}
                >
                  <div className='vacancyForm-file-input' onClick={() => dropZoneRef.current?.open()}>
                    <input name='file' {...getInputProps()} />
                    <p className='vacancyForm-file-placeholder'>
                      {t('containers_careers_components_lookingFor_vacancyDetails_vacancyForm.upload')}
                      <br />
                      <span>(PDF, DOC, PNG, JPEG, EXCEL )</span>
                    </p>
                  </div>
                  <p className='vacancyForm-file-description'>
                    {errors.file
                      ? errors.file
                      : file
                      ? `${t('containers_careers_components_lookingFor_vacancyDetails_vacancyForm.attached')} ${
                          file.filename
                        }`
                      : t('containers_careers_components_lookingFor_vacancyDetails_vacancyForm.file_here')}
                    {file && (
                      <img
                        src='/icons/ic_remove.svg'
                        className='close-ico'
                        alt='close'
                        onClick={() => setFieldValue('file', undefined)}
                      />
                    )}
                  </p>
                </section>
                <button
                  type='submit'
                  disabled={!validationSchema.isValidSync(values)}
                  onClick={() => handleSubmit()}
                  className='vacancyForm-submitBtn'
                >
                  {t('containers_careers_components_lookingFor_vacancyDetails_vacancyForm.send')}
                </button>
              </div>
            )}
          </Dropzone>
        );
      }}
    </Formik>
  );
};

export default VacancyForm;
