import { all, fork } from 'redux-saga/effects';
import { appSaga } from '../containers/App/store';
import { authSaga } from '../containers/Auth/store';
import { careersSaga } from '../containers/Careers/store';
import { landingSaga } from '../containers/Landing/store';
import { successSaga } from '../containers/SuccessStories/store';

const allSagas = [appSaga, careersSaga, landingSaga, authSaga, successSaga];

export default function* sagas() {
  yield all(allSagas.map(fork));
}
