const landingAnchors = {
  COMPANY: '#company',
  SERVICES: '#services',
  TECHNOLOGY: '#technology',
  CONTACT: '#contact'
};

const privateRoutesNames = {
  DASHBOARD: '/dashboard',
  DASHBOARD_CAREERS: '/dashboard/careers',
  DASHBOARD_SUCCESS: '/dashboard/success',
  DASHBOARD_MESSAGES: '/dashboard/messages'
};

const routesNames = {
  HOME: '/',
  CAREERS: '/careers',
  SUCCESS: '/success',
  DASHBOARD_LOGIN: '/dashboard/login'
};

export { landingAnchors, routesNames, privateRoutesNames };
