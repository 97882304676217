import classnames from 'classnames';
import {FormikErrors} from 'formik';
import {RangeStatic, Sources} from 'quill';
import React, {useRef} from 'react';
import ReactQuill from 'react-quill';
import {formats, modules} from "./QuillToolbar/QuillToolbar";
import './styledEditor.scss'

interface IStyledEditor {
    className?: string;
    value?: string;
    placeholder?: string;
    error?: boolean;
    helperText?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[];
    label?: string;
    required?: boolean;
    onChange: (value: string) => void;
    onFocus?: (previousRange: RangeStatic,
               source: Sources,
               editor: any) => void;
    onBlur?: (range: RangeStatic,
              source: Sources,
              editor: any) => void;
    disabled?: boolean;
}

const StyledEditor: React.FC<IStyledEditor> = props => {
    const {
        className,
        value = '',
        onChange,
        placeholder,
        error,
        helperText,
        label,
        required,
        onBlur,
        onFocus,
        disabled
    } = props;

    const quillRef = useRef(value) as any

    return <div className={classnames('styledEditor', className, {disabled, error})}>
        {label && <p className={classnames('styledEditor-label', {'defaultLabel-required': required})}>
            {label}
        </p>}
        <div className='styledEditor-editor'>
            <ReactQuill
                value={value}
                ref={quillRef}
                placeholder={placeholder}
                theme='snow'
                onChange={onChange}
                // onChange={()=>onChange(quillRef.current.editingArea.innerHTML)}
                modules={modules}
                formats={formats}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
        {helperText && <p className='styledEditor-helperText'>{helperText}</p>}
    </div>
}

export default StyledEditor
