import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import history from '../../../shared/history/history';
import { tokenHandler } from "../../../shared/utils";
import {privateRoutesNames, routesNames} from '../../App/constants';
import api from "../api";
import { actions, constants } from "./";

function* loginSaga({ payload }: AnyAction) {
  const {callback, ...values} = payload
  try {
    const { token } = yield call(api.login, values);
    yield tokenHandler.set(token);
    yield put(actions.login.success());
    history.push(privateRoutesNames.DASHBOARD)
  } catch ({ message }) {
    callback(message);
    yield put(actions.login.failure(message));
  }
}

function* logoutSaga() {
  yield call(() => localStorage.clear());
  yield tokenHandler.set("");
  yield put(actions.logout.success());
  history.push(routesNames.DASHBOARD_LOGIN);
}

export function* authSaga() {
  yield takeLatest(constants.AuthActionTypes.LOGIN, loginSaga);
  yield takeLatest(constants.AuthActionTypes.LOGOUT, logoutSaga);
}
