import { call, put } from "redux-saga/effects";
import { AppActionTypes } from "../../containers/App/store/constants";

const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time));



export default function* notification(message: string, type: string) {
    yield put({
      type: AppActionTypes.SHOW_NOTIFICATION, payload: {
        state: true,
        message,
        type
      }
    })
    yield call(delay, 2000);
    yield put({ type: AppActionTypes.HIDE_NOTIFICATION })
  }