// @ts-ignore
import Parallax from 'parallax-js'
import React, {useLayoutEffect, useRef} from 'react';
import './scene.scss'

interface IScene {
    go?: boolean;
}

// @ts-ignore
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

const Scene: React.FC<IScene> = props => {
    const {go} = props;
    const sceneRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const {current} = sceneRef;
        current && !isSafari && new Parallax(current)
    }, [])

    useLayoutEffect(() => {
        const {current} = sceneRef;
        if (current) {
            const bigger = current.querySelectorAll('.layer.bigger')
            bigger.forEach(i => go && !isSafari ?
                i.classList.add('go') :
                i.classList.remove('go'))
        }
    }, [go])

    return <div id="scene" ref={sceneRef}>
        {/* <div className="layer" data-depth="0.20">
            <div><img src="/images/scene/sq8.png" alt='layer'/></div>
        </div>
        <div className="layer" data-depth="0.25">
            <div>
                <img src="/images/scene/sq1.png" alt='layer'/>
            </div>
        </div>
        <div className="layer bigger" data-depth="0.20">
            <div>
                <img src="/images/scene/sq10.png" alt='layer'/>
            </div>
        </div>
        <div className="layer" data-depth="0.25">
            <div>
                <img src="/images/scene/sq2.png" alt='layer'/>
            </div>
        </div>
        <div className="layer bigger" data-depth="0.15">
            <div>
                <img src="/images/scene/sq5.png" alt='layer'/>
            </div>
        </div>
        <div className="layer bigger" data-depth="0.10">
            <div>
                <img src="/images/scene/sq11.png" alt='layer'/>
            </div>
        </div>
        <div className="layer" data-depth="0.30">
            <div>
                <img src="/images/scene/sq3.png" alt='layer'/>
            </div>
        </div>
        <div className="layer" data-depth="0.20">
            <div>
                <img src="/images/scene/sq8.png" alt='layer'/>
            </div>
        </div>
        <div className="layer" data-depth="0.30">
            <div>
                <img src="/images/scene/sq1.png" alt='layer'/>
            </div>
        </div>
        <div className="layer" data-depth="0.25">
            <div>
                <img src="/images/scene/sq4.png" alt='layer'/>
            </div>
        </div>
        <div className="layer" data-depth="0.25">
            <div>
                <img src="/images/scene/sq8.png" alt='layer'/>
            </div>
        </div>
        <div className="layer bigger" data-depth="0.15">
            <div>
                <img src="/images/scene/sq9.png" alt='layer'/>
            </div>
        </div>
        <div className="layer bigger" data-depth="0.10">
            <div>
                <img src="/images/scene/sq6.png" alt='layer'/>
            </div>
        </div>
        <div className="layer" data-depth="0.30">
            <div>
                <img src="/images/scene/sq7.png" alt='layer'/>
            </div>
        </div>
        <div className="layer" data-depth="0.25">
            <div>
                <img src="/images/scene/sq3.png" alt='layer'/>
            </div>
        </div> */}
    </div>
}

export default Scene
