import { createAsyncAction } from "typesafe-actions";
import {RequestWithCallback} from '../../../shared/interfaces/sagas';
import { IAuthShape } from "../interfaces";
import { AuthActionTypes } from "./constants";

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE,
)<undefined, undefined, undefined>();

export const login = createAsyncAction(
  AuthActionTypes.LOGIN,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAILURE,
)<RequestWithCallback<IAuthShape>, undefined, string>();
