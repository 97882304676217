import React from 'react';
import { List } from '../../../shared/components';
import { useSelector } from 'react-redux';
import { TimelycodeStore } from '../../../shared/types';
import { ISuccessStory } from '../interfaces';
import { useTranslation } from 'react-i18next';

interface ISuccessProps {
  details: ISuccessStory | undefined;
}

const SuccessDetails = (props: ISuccessProps) => {
  const { details } = props;
  const { changeLanguage } = useSelector((state: TimelycodeStore) => state.app);
  const {
    t,
    i18n: { language = 'en' }
  } = useTranslation();

  if (!details) return null;

  const { title, _id, link = '', image_base64, presentation, technologies, ...rest } = details;
  const filteredKeys = Object.keys(rest).filter((key: string) => key.includes(`_${language}`));
  return (
    <div className='successDetails' id='successDetails'>
      <div
        className={`successDetails-description ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}
        key={_id}
      >
        <List
          title={title}
          list={
            // @ts-ignore
            filteredKeys.map((key: string) => t(`${key}`) + rest[key])
          }
        >
          <>
            <li className='listWrapper-list-item'>
              <p>
                {t('technologies')}
                {technologies}
              </p>
            </li>
            {link && (
              <li className='listWrapper-list-item'>
                <p>
                  {t('link')}
                  <a href={link} rel='noopener noreferrer' target='_blank'>
                    {link}
                  </a>
                </p>
              </li>
            )}
            {presentation && (
              <li className='listWrapper-list-item'>
                <p>
                  <a download={`${title}_presentation`} href={presentation}>
                    {t('presentation')}
                  </a>
                </p>
              </li>
            )}
          </>
        </List>
      </div>
    </div>
  );
};

export default SuccessDetails;
