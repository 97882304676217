import classnames from 'classnames';
import React from 'react';
import {IService, ITranslation} from '../../../interfaces';
import './serviceItem.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getChangeLanguage} from "../../../../App/store/selectors";

interface IServiceItemProps{
    item: IService;
    className?: string;
}

const ServiceItem: React.FC<IServiceItemProps> = props => {
    const {item, className} = props;
    const { i18n: { language } } = useTranslation() as ITranslation
    const changeLanguage = useSelector(getChangeLanguage())

    return <div className={classnames("service", className)}>
        <img className={`service-image ${changeLanguage ? 'flip-in-hor-top_start' : 'flip-in-hor-top_end'}`} src={item.image_base64} alt={item[`title_${language}`]}/>
        <h3 className={`service-title ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}>{item[`title_${language}`]}</h3>
        <p className={`service-text ${changeLanguage ? 'animation_opacity_start' : 'animation_opacity_end'}`}>{item[`text_${language}`]}</p>
    </div>
}

export default ServiceItem
