import produce from "immer";
import { Reducer } from "redux";
import { tokenHandler } from "../../../shared/utils";
import { IAuthState } from "../interfaces";
import { AuthActionTypes } from "./constants";

const initialState: IAuthState = {
  authentificated: !!tokenHandler.get(),
};

export const AuthReducer: Reducer<IAuthState> = (state: IAuthState = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGOUT_FAILURE:
      return state;
    case AuthActionTypes.LOGOUT:
      localStorage.clear()
      return produce(state, nextState => {
        nextState.authentificated = false;
      });
    case AuthActionTypes.LOGOUT_SUCCESS:
      localStorage.clear()
      return produce(state, nextState => {
        nextState.authentificated = false;
      });
    case AuthActionTypes.LOGIN_SUCCESS:
      return produce(state, nextState => {
        nextState.authentificated = true;
      });
    default:
      return state;
  }
};
