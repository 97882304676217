import { Configuration, ConfigurationObject } from './shared/interfaces';

const { REACT_APP_ENV = 'production' } = process.env;

const dev: Configuration = {
  env: REACT_APP_ENV,
  baseApiUrl: 'http://localhost:4000/api',
  applicationUrl: 'http://localhost:3000'
};

const stage: Configuration = {
  ...dev,
  baseApiUrl: 'http://15.237.106.89/api',
  applicationUrl: 'http://15.237.106.89'
};

const production: Configuration = {
  ...dev,
  baseApiUrl: 'https://www.timelycode.com/api',
  applicationUrl: 'https://www.timelycode.com'
};

const config: ConfigurationObject = {
  dev: dev,
  stage: stage,
  production: production
};

const configElement: Configuration = (config as any)[REACT_APP_ENV];
export default configElement;
