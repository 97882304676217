type Behaviour = 'smooth' | 'auto' | undefined

const scrollTo = (element: HTMLElement, offsetTop: number = 0, delay?: number, behavior: Behaviour = "smooth") => {
    if (typeof window === "undefined") {
        // tslint:disable-next-line:no-console
        console.log("no window");
        return;
    }

    window.requestAnimationFrame(() => {
        let offset = element.offsetTop;
        try {
            let bodyRect = document.body.getBoundingClientRect();
            let bodyStyle = window.getComputedStyle(document.body, null);

            // need to handle the padding for the top of the body
            let paddingTop = parseFloat(bodyStyle.getPropertyValue("padding-top"));

            let elementRect = element.getBoundingClientRect();
            offset = elementRect.top - paddingTop - bodyRect.top - offsetTop;
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.log(err);
        }

        if (delay) {
            setTimeout(() => {
                window.scrollTo({ top: offset, left: 0, behavior });
            }, delay);
        } else {
            window.scrollTo({ top: offset, left: 0, behavior });
        }
    });
}

const scrollTop = (delay?: number, behavior: Behaviour = "smooth") => {
    if (typeof window === "undefined") return;

    window.requestAnimationFrame(() => {
        if (delay) {
            setTimeout(() => {
                window.scroll({ top: 0, left: 0, behavior });
            }, delay);
        } else window.scroll({ top: 0, left: 0, behavior });
    });
}

export  {scrollTop, scrollTo}
