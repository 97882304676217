import React from 'react';
import "./styledSelectLang.scss";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {actions} from "../../../containers/App/store";

interface ISelectLang {
    path: string;
    // style: React.CSSProperties | undefined;
}

const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time));

const SelectLang: React.FC<ISelectLang> = props => {
    const {
        path,
    } = props;
    const {i18n} = useTranslation()
    const dispatch = useDispatch();

    const lngProcess = async (type: string) => {
        await dispatch(actions.langProcess(false))
        await i18n.changeLanguage(type)
        await delay(200)
        dispatch(actions.langProcess(true))
    }
    const lngs = [
        {type: 'en', nativeName: 'English', path: '/icons/en.svg'},
        {type: 'ukr', nativeName: 'Українська', path: '/icons/ukr.svg'}
    ]

    return (
        <ul className="lang-menu">
            <li>
                <img src={path} alt="lang"/>
                <ul>
                    {lngs.map((lng, i) => (
                        (i18n.resolvedLanguage !== lng.type)
                            ? <li key={i} onClick={() => lngProcess(lng.type)}>
                                <img src={lng.path} alt={lng.nativeName}/>
                            </li>
                            : null
                    ))}
                </ul>
            </li>
        </ul>


    );
};

export default SelectLang;
