import { API, METHODS } from '../../../shared/constants';
import { request } from '../../../shared/utils';
import { IOffer } from '../../App/interfaces';
import { IMessage } from '../../Dashboard/containers/Messages/interfaces/IMessage';
import { IInTouchData, IVacancy } from '../interfaces';

export default {
  getInTouch: () => request(METHODS.GET, API.CAREERS.IN_TOUCH)(),
  updateInTouch: (payload: IInTouchData) => request(METHODS.PUT, API.CAREERS.IN_TOUCH)(payload),
  createInTouch: (payload: IInTouchData) => request(METHODS.POST, API.CAREERS.IN_TOUCH)(payload),
  deleteInTouch: (id: string) => request(METHODS.DELETE, `${API.CAREERS.IN_TOUCH}/${id}`)(),

  createVacancy: (payload: IVacancy) => request(METHODS.POST, API.CAREERS.CREATE_VACANCY)(payload),
  getVacancies: () => request(METHODS.GET, API.CAREERS.VACANCIES)(),
  updateVacancy: (payload: IVacancy) => request(METHODS.POST, API.CAREERS.VACANCIES)(payload),
  deleteVacancy: (payload: object) => request(METHODS.POST, API.CAREERS.DELETE_VACANCY)(payload),

  createOffer: (payload: IOffer) => request(METHODS.POST, API.CAREERS.CREATE_OFFER)(payload),
  getOffer: () => request(METHODS.GET, API.CAREERS.OFFER)(),
  updateOffer: (payload: IOffer) => request(METHODS.POST, API.CAREERS.OFFER)(payload),
  deleteOffer: (payload: object) => request(METHODS.POST, API.CAREERS.DELETE_OFFER)(payload),

  sendMessage: (payload: IMessage) => request(METHODS.POST, API.CAREERS.MESSAGES)(payload),
  getMessages: () => request(METHODS.GET, API.CAREERS.MESSAGES)(),
  deleteMessage: (id: string) => request(METHODS.DELETE, `${API.CAREERS.MESSAGES_DELETE}/${id}`)()
};
