export default {
	containers_careers_components_inTouch: {
		title: 'IN TOUCH',
		preLine: 'GET'
	},
	containers_careers_components_lookingFor: {
		title: 'LOOKING FOR',
		preLine: 'WE\'RE'
	},
	containers_careers_components_lookingFor_vacancyDetails: {
		req_skills: 'Required skills:',
		optional: 'Optional:',
		must_have_skills: 'Must have skills:'
	},
	containers_careers_components_lookingFor_vacancyDetails_vacancyForm: {
		invalid_mail: 'Invalid email',
		req_mail: 'Required!',
		req_file: 'File is required field',
		onDrop_error: 'Unsupported file format',
		name_placeholder: 'Your name *',
		email_placeholder: 'Email *',
		phone_placeholder: 'Phone *',
		upload: 'Upload cv',
		attached: 'You\'ve attached:',
		file_here: 'You can drop your file here',
		send: 'Send'
	},
	containers_careers_components_offer: {
		title: 'offer',
		preLine: 'we'
	},
	containers_careers_components_lookingFor_vacancyPrev_details: 'VIEW DETAILS',
}