import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertComponent } from '../../../../shared/components';
import { getNotification } from '../../../App/store/selectors';
import { actions } from '../../../Careers/store';
import { getMessages } from '../../../Careers/store/selectors';
import { MessagePreview } from './messagePreview/MessagePreview';
import './messages.scss';

const Messages: React.FC = () => {
  const messages = useSelector(getMessages());
  const dispatch = useDispatch();
  const { message, type, state } = useSelector(getNotification());

  useEffect(() => {
    dispatch(actions.getMessages.request());
  }, [dispatch]);
  return (
    <div className='sectionTitle' id='messages'>
      {messages && messages.length && messages.map(item => <MessagePreview item={item} key={item._id} />)}
      {state && <AlertComponent message={message} className={type} />}
    </div>
  );
};

export { Messages };
