export default {
	containers_dashboard_containers_careers_components_lookingFor_addresses: 'Адреси:',
	containers_dashboard_containers_landing_components_companyDescription: {
		title_placeholder_en: 'Назва англійською',
		title_label_en: 'Назва англійською',
		description_placeholder_en: 'Опис англійською',
		description_label_en: 'Опис англійською',
		title_placeholder_ukr: 'Назва українською',
		title_label_ukr: 'Назва українською',
		description_placeholder_ukr: 'Опис українською',
		description_label_ukr: 'Опис українською',
		submit: 'Підтвердити'
	},
	containers_dashboard_containers_landing_components_contacts: {
		title: 'Контакти',
		links: 'Посилання на колонтитул:',
		address: 'Адреси:',
		contacts: 'Контакти:',
	},
	containers_dashboard_containers_messages_singleMess: {
		messFrom: 'Повідомлення від:',
		received: 'Отримано:',
		interesting: 'Мені цікава вакансія:',
		contacts: 'Контакти:',
	},
}
