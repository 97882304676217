import { Formik } from 'formik';
import React, { useEffect, useState, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { StyledInput } from '../../../../shared/components/StyledInput';
import { InputTypes } from '../../../../shared/constants';
import { privateRoutesNames } from '../../../App/constants';
import { IAuthShape } from '../../interfaces';
import { actions } from '../../store';
import { authentificated } from '../../store/selectors';
import './login.scss';

const initialValues: IAuthShape = {
  username: '',
  password: ''
};

const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(authentificated());
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    username: Yup.string().required(t('containers_auth_components_login.f_required')),
    password: Yup.string().required(t('containers_auth_components_login.f_required'))
  });

  useEffect(() => {
    isAuthenticated && history.push(privateRoutesNames.DASHBOARD);
  }, [isAuthenticated, history]);

  const loginHandler = (values: IAuthShape) => {
    dispatch(actions.login.request({ ...values, callback: setErrorMessage }));
  };

  const resetErrorMessage = () => !!errorMessage && setErrorMessage('');

  return (
    <div className='loginWrapper'>
      <Formik onSubmit={loginHandler} validationSchema={validationSchema} initialValues={initialValues}>
        {({ values, handleChange, handleSubmit, touched, errors }) => {
          const { password, username } = values;

          return (
            <div className='login'>
              <img className='login-image' src='/images/big_logo.svg' alt='logo' />
              <StyledInput
                placeholder={t('containers_auth_components_login.username_placeholder')}
                onChange={(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  handleChange(e);
                  resetErrorMessage();
                }}
                name='username'
                error={touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                value={username}
              />
              <StyledInput
                type={InputTypes.password}
                placeholder={t('containers_auth_components_login.password_placeholder')}
                onChange={(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  handleChange(e);
                  resetErrorMessage();
                }}
                name='password'
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                value={password}
              />
              {!!errorMessage && <p className='login-errorMessage'>{errorMessage}</p>}
              <button
                disabled={!validationSchema.isValidSync(values)}
                className='login-submitBtn'
                onClick={() => handleSubmit()}
                type='submit'
              >
                {t('containers_auth_components_login.submit')}
              </button>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default Login;
