import { API, METHODS } from '../../../shared/constants';
import { request } from '../../../shared/utils';
import { ISuccessStory } from '../interfaces';

export default {
  createSuccessStory: (payload: ISuccessStory) => request(METHODS.POST, API.SUCCESS.STORIES)(payload),
  getSuccessStories: () => request(METHODS.GET, API.SUCCESS.STORIES)(),
  updateSuccessStory: (payload: ISuccessStory) => request(METHODS.PUT, API.SUCCESS.STORIES)(payload),
  deleteSuccessStory: (id: string) => request(METHODS.DELETE, API.SUCCESS.STORY_DELETE + id)(),
  getSummarySuccess: () => request(METHODS.GET, API.SUCCESS.SUMMARY)(),
  updateSummarySuccess: (payload: ISuccessStory) => request(METHODS.PUT, API.SUCCESS.SUMMARY)(payload)
};
