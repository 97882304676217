import { AnyAction } from 'redux';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { Type } from '../../App/constants/notificationType';
import api from '../api/api';
import { actions } from './';
import { CareersActionTypes } from './constants';
import notification from '../../../shared/utils/notification';
import i18n from 'i18next';

function* createVacancy({ payload }: AnyAction) {
  try {
    const res = yield call(api.createVacancy, payload);
    if (res) {
      const vacancies = yield call(api.getVacancies);
      yield put(actions.getVacancies.success(vacancies));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* getVacancies() {
  try {
    const vacancies = yield call(api.getVacancies);
    yield put(actions.getVacancies.success(vacancies));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getVacancies.failure());
  }
}

function* updateVacancy({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateVacancy, payload);
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS);
      const vacancies = yield call(api.getVacancies);
      yield put(actions.getVacancies.success(vacancies));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getVacancies.failure());
  }
}

function* deleteVacancy({ payload }: AnyAction) {
  try {
    const res = yield call(api.deleteVacancy, payload);
    if (res) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS);
      const vacancies = yield call(api.getVacancies);
      yield put(actions.getVacancies.success(vacancies));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* createOffer({ payload }: AnyAction) {
  try {
    const res = yield call(api.createOffer, payload);
    if (res) {
      const offer = yield call(api.getOffer);
      yield put(actions.getOffer.success(offer));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* getOffer() {
  try {
    const offer = yield call(api.getOffer);
    yield put(actions.getOffer.success(offer));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getOffer.failure());
  }
}

function* updateOffer({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateOffer, payload);
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS);
      const offer = yield call(api.getOffer);
      yield put(actions.getOffer.success(offer));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* deleteOffer({ payload }: AnyAction) {
  try {
    const res = yield call(api.deleteOffer, payload);
    if (res) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS);
      const offer = yield call(api.getOffer);
      yield put(actions.getOffer.success(offer));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* getInTouchData() {
  try {
    const inTouchData = yield call(api.getInTouch);
    yield put(actions.getInTouchData.success(inTouchData));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getInTouchData.failure());
  }
}

function* updateInTouchData({ payload }: AnyAction) {
  try {
    const res = yield call(api.updateInTouch, payload);
    if (res) {
      yield fork(notification, i18n.t('SAVED_SUCCESSFULLY'), Type.SUCCESS);
      const inTouchData = yield call(api.getInTouch);
      yield put(actions.getInTouchData.success(inTouchData));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
    yield put(actions.getInTouchData.failure());
  }
}

function* createInTouch({ payload }: AnyAction) {
  try {
    const res = yield call(api.createInTouch, payload);
    if (res) {
      const inTouchData = yield call(api.getInTouch);
      yield put(actions.getInTouchData.success(inTouchData));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* deleteInTouchData({ payload }: AnyAction) {
  try {
    const deleting = yield call(api.deleteInTouch, payload._id);
    if (deleting) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS);
      const inTouchData = yield call(api.getInTouch);
      yield put(actions.getInTouchData.success(inTouchData));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* sendMessage({ payload }: any) {
  try {
    const name = yield call(api.sendMessage, payload);
    if (name) {
      yield fork(notification, `Thanks ${name}, we'll contact with you`, Type.SUCCESS);
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* getMessages() {
  try {
    const messages = yield call(api.getMessages);
    yield put(actions.getMessages.success(messages));
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

function* deleteMessage({ payload }: AnyAction) {
  try {
    const deleting = yield call(api.deleteMessage, payload.id);
    if (deleting) {
      yield fork(notification, i18n.t('DELETED_SUCCESSFULLY'), Type.SUCCESS);
      const messages = yield call(api.getMessages);
      yield put(actions.getMessages.success(messages));
    }
  } catch (e) {
    yield fork(notification, `${i18n.t('ERROR')}: ${e.message}`, Type.ERROR);
  }
}

export default function* careersSaga() {
  yield takeLatest(CareersActionTypes.CREATE_OFFER_REQUEST, createOffer);
  yield takeLatest(CareersActionTypes.GET_OFFER_REQUEST, getOffer);
  yield takeLatest(CareersActionTypes.UPDATE_OFFER_REQUEST, updateOffer);
  yield takeLatest(CareersActionTypes.DELETE_OFFER_REQUEST, deleteOffer);

  yield takeLatest(CareersActionTypes.CREATE_VACANCIES_REQUEST, createVacancy);
  yield takeLatest(CareersActionTypes.GET_VACANCIES_REQUEST, getVacancies);
  yield takeLatest(CareersActionTypes.UPDATE_VACANCIES_REQUEST, updateVacancy);
  yield takeLatest(CareersActionTypes.DELETE_VACANCIES_REQUEST, deleteVacancy);

  yield takeLatest(CareersActionTypes.GET_IN_TOUCH_REQUEST, getInTouchData);
  yield takeLatest(CareersActionTypes.CREATE_IN_TOUCH_REQUEST, createInTouch);
  yield takeLatest(CareersActionTypes.UPDATE_IN_TOUCH_REQUEST, updateInTouchData);
  yield takeLatest(CareersActionTypes.DELETE_IN_TOUCH_REQUEST, deleteInTouchData);

  yield takeLatest(CareersActionTypes.SEND_MESSAGE_REQUEST, sendMessage);
  yield takeLatest(CareersActionTypes.GET_MESSAGE_REQUEST, getMessages);
  yield takeLatest(CareersActionTypes.DELETE_MESSAGE_REQUEST, deleteMessage);
}
