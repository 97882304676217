import containers_app_ukr from '../../containers/App/i18n/ukr';
import containers_auth_ukr from '../../containers/Auth/i18n/ukr';
import containers_careers_ukr from '../../containers/Careers/i18n/ukr';
import containers_dashboard_ukr from '../../containers/Dashboard/i18n/ukr';
import containers_landing_ukr from '../../containers/Landing/i18n/ukr';
import shared_ukr from '../../shared/i18n/ukr';
import success_stories from '../../containers/SuccessStories/i18n/ukr';

export default {
  path_flag: '/icons/ukr.svg',
  ...containers_app_ukr,
  ...containers_auth_ukr,
  ...containers_careers_ukr,
  ...containers_dashboard_ukr,
  ...containers_landing_ukr,
  ...shared_ukr,
  ...success_stories
};
