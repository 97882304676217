import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Slider from 'react-slick';
import {SectionTitle} from '../../../../shared/components/SectionTitle';
import {baseSlickSettings} from '../../../../shared/constants';
import {actions, selectors} from '../../store';
import {ServiceItem} from './ServiceItem';
import './services.scss'
import {useTranslation} from "react-i18next";

const Services: React.FC = () => {
    const services = useSelector(selectors.getServices());
    const { t } = useTranslation()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getServices.request())
    }, [dispatch])

    return <section className='services section container'>
        <SectionTitle preLine={t('containers_landing_components_services.preLine')} title={t('containers_landing_components_services.title')}/>
        <div className="services-list">
            <Slider {...{...baseSlickSettings, slidesToShow: services.length || 1}}>
                {services.map((item) => <ServiceItem item={item} key={item._id}/>)}
            </Slider>
        </div>
    </section>
}

export default Services
