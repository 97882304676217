import React from 'react';
import '../../../../../shared/components/Alert/alertStyle.scss';
import { IMessage } from '../interfaces/IMessage';
import './singleMessage.scss';
import { useTranslation } from 'react-i18next';

interface ISingleMessageProps {
  item: IMessage;
  closeMessage: Function;
}

const SingleMessage: React.FC<ISingleMessageProps> = ({ item, closeMessage }) => {
  const { name, file, message, date, vacancy, email, phone } = item;
  const { t } = useTranslation();
  return (
    <div className='background'>
      <div className='message flex column'>
        <div className='full-width flex column underline'>
          <img src='/icons/ic_remove.svg' alt='close' onClick={() => closeMessage()} className='close' />
          <span>
            <span className='secondary'>{t('containers_dashboard_containers_messages_singleMess.messFrom')}</span>{' '}
            {name}
          </span>
          <span>
            <span className='secondary'>{t('containers_dashboard_containers_messages_singleMess.received')}</span>{' '}
            {date}
          </span>
          <span>
            <span className='secondary'>{t('containers_dashboard_containers_messages_singleMess.contacts')}</span>{' '}
            {email}, {phone}
          </span>
        </div>
        <div className='message-content underline'>
          {message ? message : `${t('containers_dashboard_containers_messages_singleMess.interesting')} ${vacancy}`}
        </div>
        {file && (
          <div>
            <p>
              Attached file:{' '}
              <a download={file.filename} href={file.file_base_64}>
                {' '}
                {file.filename}
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export { SingleMessage };
